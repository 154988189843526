import React from 'react';
import { Button } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { Link, useNavigate } from "react-router-dom"

import {styles} from '../../themes/theme-white';

import firebaseService from '../../services/Firebase/firebase';

const MENU = [
	{ id: 1, title: 'Home', link: '/' },
	{ id: 2, title: 'Users', link: '/users' },
	{ id: 3, title: 'Events', link: '/events' },
	{ id: 4, title: 'Commercial', link: '/commercial' },
	 {id: 5, title: 'Archive', link: '/archive' }
]

function Sidebar({classes, isActiveMenu}) {
    
    const navigate = useNavigate();

    const logout = async () => {

		try {
			await firebaseService.logout().then( () => {
                    navigate("/");
                    console.log("Signed out successfully")
                }).catch((error) => {
					console.log('error: ', error);
                // An error happened.
                });
		} catch(error) {
			alert('Sign out wrong!', error)
		}
	}

	return (
		<div style={style.menuContainer} className='menuContainer'>
			<div style={{display: 'flex', paddingTop: 30, justifyContent: 'center'}}>
				<span style={{fontSize: 18, fontFamily:'Nexa-Bold'}}>Involved Admin v1.0</span>
			</div>

			<nav>
                <ul style={{flex: 0, listStyle:'none', textAlign: 'left', paddingRight: 70}} className={'testName'}>

					{
						MENU.map( (item, index) => {
							return (
								<li key={`menuItem${index}`} style={{marginTop: index === 0 ? '50px' : '5px'}}>
									<Link className="menuItemContainer" style={{...style.menuItemContainer, fontWeight: isActiveMenu === index + 1 ? 600 : 400}} to={item.link}>
										{item.title}
									</Link>
								</li>
							)
						})
					}

					{/*                     
                    <li style={{marginTop: '50px'}}><Link className="menuItemContainer" style={{...style.menuItemContainer, fontWeight: isActiveMenu === 1 ? 600 : 400}} to="/">Home</Link></li>
                    <li style={{marginTop: '5px'}}><Link className="menuItemContainer" style={{...style.menuItemContainer, fontWeight: isActiveMenu === 2 ? 600 : 400}} to="/users">Users</Link></li>
                    <li style={{marginTop: '5px'}}><Link className="menuItemContainer" style={{...style.menuItemContainer, fontWeight: isActiveMenu === 3 ? 600 : 400}} to="/">Events</Link></li>
                    <li style={{marginTop: '5px'}}><Link className="menuItemContainer" style={{...style.menuItemContainer, fontWeight: isActiveMenu === 4 ? 600 : 400}} to="/">Commercial</Link></li>
                    <li style={{marginTop: '5px'}}><Link className="menuItemContainer" style={{...style.menuItemContainer, fontWeight: isActiveMenu === 5 ? 600 : 400}} to="/">Archive</Link></li> */}


                    <Button
						type="submit"
                        // disabled = { firebase.getCurrentUsername() ? true : false }
						// fullWidth
						variant="contained"
						color="secondary"
						onClick={logout}
						className={classes.submit}>
						Sign out
          			</Button>
                </ul>
            </nav>
			
		</div>
	)

}

const style = {
	menuContainer: {
		minidth: '20%',
		minHeight: '100%',
		background: '#fff',
		borderRight: '1px solid #999',
		padding: '0 10px 0 10px'
	},
	menuItemContainer: {
		color: '#55008E',
		textDecoration: 'none',
		marginTop: '120px',
	}
}


export default withStyles(styles)(Sidebar)