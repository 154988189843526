import { initializeApp } from "firebase/app";
import {  signInWithEmailAndPassword, getAuth, signOut } from 'firebase/auth';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID
}


const app = initializeApp(firebaseConfig);

class Firebase {
	constructor() {
		// app.initializeApp(firebaseConfig)
		this.auth = getAuth(app)
	}

	test() {
		console.log('this.auth', this.auth);
	}

	async login(email, password) {
		return signInWithEmailAndPassword(this.auth, email, password)
	}

	logout() {
		return signOut(this.auth)
	}

	isInitialized() {
		return new Promise(resolve => {
			this.auth.onAuthStateChanged(resolve)
		})
	}

	getCurrentUsername() {
		const authStatus = this.auth.currentUser?.uid || '' 
		return authStatus
	}

}

const firebaseService = new Firebase();

export default firebaseService;