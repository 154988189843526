import React, { useEffect, useState, useRef } from 'react';

import {
    AppBar,
    Toolbar,
    Typography,
    Tabs,
    Tab,
    Box,
    makeStyles,
    useTheme,
    useMediaQuery,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Button,
    Container,
    Grid,
    ListItemIcon
} from '@material-ui/core';

// import { Truck, Storefront, Package } from '@material-ui/icons';

import { LocalShipping, Storefront, Package } from '@mui/icons-material';

import AOS from 'aos';

import AssistantPhotoTwoToneIcon from '@material-ui/icons/AssistantPhotoTwoTone';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import StorefrontTwoToneIcon from '@material-ui/icons/StorefrontTwoTone';

import Slider from 'react-slick';

import { withStyles } from '@material-ui/core/styles';
import { aboutUsStyles } from '../../../themes/theme-white';

import aboutUs from '../../../assets/about/about1.jpg';

import config from '../../../config/config';

function AboutUsSection({classes}) {

    const theme = useTheme();

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    const features = [
        { 
            title: 'Прямий перевізник', 
            shortDescription: 'Вся логістика здійснюється безпосередньо через нас, без участі сторонніх посередників. Це означає, що ваш вантаж не перенаправляється на склади інших компаній і не передається третім особам. Ми беремо на себе всю відповідальність за кожен етап перевезення, від прийому вантажу до його доставки', 
            // shortDescription: 'Ми не є посередниками, ваш вантаж не перенаправляється на склади інших компаній, а безпосередньо доставляється найбільш швидким способом', 
            icon: <LocalShippingTwoToneIcon  className={classes.featureIcon} />
        },
        { 
            title: 'Власні склади', 
            shortDescription: 'Маючи власні склади, ми можемо безпосередньо контролювати всі етапи ланцюга постачання. Це дозволяє нам забезпечити чітке планування і організацію процесів завантаження, зберігання та доставки вантажів. Завдяки власним складам ми здатні оптимізувати логістичні процеси, забезпечуючи швидке і точне виконання ваших замовлень', 
            // shortDescription: 'Власні склади - це можливість більш ефективно управляти ланцюжком поставок, мінімізувати ризики та підвищити рівень обслуговування клієнтів. Це стратегічна перевага нашої компанії', 
            icon: <StorefrontTwoToneIcon className={classes.featureIcon} />
        },
        { 
            title: 'Безкоштовна консолідація вантажів на своїх складах', 
            shortDescription: `Ми пропонуємо безкоштовну консолідацію вантажів на наших складах, що дозволяє об'єднати кілька відправлень в один вантаж для економії на транспортуванні. Це забезпечує ефективне управління вашими вантажами, знижує витрати на доставку та підвищує рівень обслуговування клієнтів`, 
            // shortDescription: 'Це стратегічний підхід, що дозволяє оптимізувати процеси зберігання та відправлення товарів. Яке економить ресурси клієнтів компанії King Cargo та робить логістичний процес більш ефективним', 
            icon: <AssistantPhotoTwoToneIcon className={classes.featureIcon} />
        },
    ];

    return (
        <Box className={classes.aboutSection}>
            <Grid container spacing={2}>
                {features.map((feature, index) => (
                    <Grid 
                        item 
                        xs={12} 
                        md={4} 
                        key={index}
                        data-aos="fade-up" 
                        data-aos-delay={index * 200}
                    >
                        <Box className={classes.featureBox}>
                            <Box className={classes.featureIconWrapper}>
                                {feature.icon}
                            </Box>
                            <Box>
                                <Typography variant="h6">{feature.title}</Typography>
                                <Typography style={{fontSize: '0.9rem', marginTop: '5px'}}>{feature.shortDescription}</Typography>
                                <Button href="/service/details" className={classes.readMoreButton}>
                                    Читати більше →
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Grid container spacing={2} className={classes.aboutUsBlock}>
                <Grid 
                    item 
                    xs={12} 
                    md={6} 
                    className={classes.aboutUsTextContainer}
                    data-aos="fade-up"
                >
                    {/* <Typography variant="h4" className={classes.title}>
                        Про нас
                        <div className={classes.line}>
                            <div className={classes.widePart}></div>
                        </div>
                    </Typography> */}
                    <Box className={classes.titleContainer}>
                        <Typography variant="h4" className={classes.title}>Про нас</Typography>
                        <Typography variant="h1" className={classes.titleBackground}>Про нас</Typography>
                    </Box>
                    <Typography className={classes.aboutUsDescription}>
                        <br /><br />
                        Наша компанія, яка вже понад 10 років на ринку, пропонує комплексні логістичні рішення для міжнародних перевезень. Ми – команда професіоналів та ваш надійний партнер, готовий забезпечити безпечне та ефективне транспортування вантажів до будь-якої країни світу. Незалежно від обсягу чи типу вантажу, ми надаємо індивідуальні рішення, орієнтовані на ваші потреби. Наші досвідчені фахівці в галузі міжнародної логістики та транспортування гарантують своєчасну та безпечну доставку, дотримуючись встановлених термінів та бюджету.
                        <br /><br />
                        У нашому арсеналі – автомобільний, морський та авіаційний транспорт. Ми гарантуємо, що вартість доставки залишається незмінною з моменту відправлення вантажу від постачальника до моменту отримання його в кінцевій точці призначення. Це зобов’язання дійсне для всіх видів міжнародних перевезень. Ми здійснюємо індивідуальний розрахунок для кожного вантажу, враховуючи всі ваші побажання.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} data-aos="fade-up" data-aos-delay="200">
                    <img src={aboutUs} alt="Про нас" className={classes.aboutUsImage} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default withStyles(aboutUsStyles)(AboutUsSection)

