const config = {
    devMode: true,
    appName: 'Logis',
    // appName: 'KingCargo',
    languages: [
        { code: 'UA', label: 'Українська'},
        { code: 'ENG', label: 'English'},
        { code: 'PL', label: 'Polski' }
    ],
    contacts: {
        phones: [
            '+380 (67) 876-54-32',
            '+380 (93) 876-54-32',
            '+380 (50) 876-54-32'
        ],
        email: 'logis.logistics@gmail.com'
    },
    hosting: {
        root: 'https://pratsdev-solutions.site'
        // root: 'http://chntestk58394.corsa.chost.com.ua'
    },
    elements: {
        showTelegramBotButton: true,
        showViberBotButton: true
    }
   
}

export default config
