import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

import backgroundImage from '../assets/slider/slider-background1.jpg';
import headerLevel2Background from '../assets/service/header5_1.jpg';
// import headerLevel2Background from '../assets/service/header3_1.jpg';

import { Opacity } from '@material-ui/icons';
import { Air } from '@mui/icons-material';
import zIndex from '@material-ui/core/styles/zIndex';

export const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', 
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
    marginTop: '-150px',
		[theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
	},
	avatar: {
		margin: theme.spacing(2),
		backgroundColor: theme.palette.secondary.main,

	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		marginTop: theme.spacing(3),
    }
})

export const homeStyles = theme => ({
	root: {
    flexGrow: 1,
  },
  section: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  contactFabContainer: {
    width: '100vw',
    height: '100vh',
    position: 'absolute'
  },
})

export const headerStyles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // width: '100vw',
    // top: 0, left: 0,
    // border: '1px solid yellow'
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  logo: {
    marginRight: theme.spacing(2),
    height: 30,
  },
  logoLink: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
  },
  tabs: {
    minWidth: 'auto',
  },
  tab: {
    minWidth: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('1350')]: {
      marginLeft: theme.spacing(0.5), 
      marginRight: theme.spacing(0.5),
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawerPaper: {
    backgroundColor: '#f5f5f5', 
    opacity: .9
  },
  drawerList: {
    width: 250,
  },
  flagIcon: {
    width: 24,
    height: 16,
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center'
  },
  languageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'space-between',
    backgroundColor: '#f5f5f5', 
    padding: theme.spacing(1),
  },
  languageButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    padding: theme.spacing(1),
    cursor: 'pointer',
  },
  languageCode: {
    fontSize: '1rem',
  },
})

export const sliderStyles = theme => ({
  sliderContainer: {
        position: 'relative',
        width: '100vw',
        height: '100vh',
        // height: '94vh',
        overflow: 'hidden',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        // border: '3px solid red'
    },
    slider: {
      height: '100%',
      display: 'flex !important',
      alignItems: 'center',
      paddingBottom: '100px',     
      [theme.breakpoints.down('sm')]: {
          paddingBottom: '200px'
      },
      [theme.breakpoints.up('md')]: {
        paddingBottom: '170px', 
    },
    },
    slideContainer:{
      marginLeft: '-25px',
      // border: '10px solid yellow'
      [theme.breakpoints.down('sm')]: {
        marginLeft: '-25px'
    }
    },
    sliderItem: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    sliderTitle: {
      fontSize: '5rem',
      marginBottom: theme.spacing(2),
      color: '#fff',
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)',
      padding: theme.spacing(1),
      borderRadius: '5px',
      fontWeight: 'bold',
      textAlign: 'center',
      textTransform: 'uppercase',  
      letterSpacing: '2px',     
      [theme.breakpoints.down('sm')]: {
          fontSize: '3rem',
      }
    },
    sliderDescription: {
        fontSize: '3rem',
        marginBottom: theme.spacing(4),
        color: '#fff',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)',
        padding: theme.spacing(1),
        borderRadius: '5px',
        fontWeight: 'bold',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        }
    },
    callButtonContainer: {
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center', 
      [theme.breakpoints.down(630)]: {
        marginTop: theme.spacing(5),
      },
      // [theme.breakpoints.down('sm')]: {
      //     marginLeft: 0,
      //     marginTop: theme.spacing(5), 
      //     border: '1px solid blue',
      // },
    },
    callButton: {
        // position: 'absolute',
        // bottom: '20%', // Позиционирование снизу вместо top
        // left: '50%',
        marginLeft: theme.spacing(10),
        transform: 'translateX(0%)', // Центрирование по горизонтали
        backgroundColor: '#1976d2', // Синий цвет из палитры Material-UI
        color: '#fff',
        padding: '12px 24px',
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        borderRadius: '10px', // Закругленные углы
        // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)',
        transition: 'all 0.3s ease',
        '&:hover': {
            backgroundColor: '#115293', // Темно-синий при наведении
            boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)', // Усиление тени при наведении
            transform: 'translateX(0%) translateY(-2px)', // Легкий эффект поднятия
        },
        '&:active': {
            backgroundColor: '#0d47a1', // Еще более темный синий при нажатии
            boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)', // Уменьшение тени при нажатии
            transform: 'translateX(0%) translateY(0)', // Возврат к исходному положению
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            // alignSelf: 'center',
            // marginTop: theme.spacing(5), 
            // marginBottom: theme.spacing(2), 
        },
       
    },
    bottomLeftBlock: {
      position: 'absolute',
      bottom: '20%',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      marginTop: theme.spacing(2),
      flexWrap: 'wrap',
      [theme.breakpoints.down('sm')]: {
        bottom: '20%',
      },
    },
    blockItem: {
        textAlign: 'center',
        color: '#fff',
        padding: theme.spacing(1),
        '&:not(:last-child)': {
            marginRight: theme.spacing(3),
        },
        [theme.breakpoints.down('sm')]: {
          marginRight: theme.spacing(1), 
        },
    },
    separator: {
        display: 'block',
        width: '20px',
        height: '3px',
        backgroundColor: '#0094FF',
        margin: '0 auto',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)',
    },
    blockLabel: {
      fontSize: '1.5rem', 
      [theme.breakpoints.down('md')]: {
          fontSize: '1rem', 
      },
      [theme.breakpoints.down('600')]: {
          fontSize: '0.8rem',
      },
      textShadow: '2px 2px 4px rgba(0, 0, 0, 1)',
  },
  blockValue: {
      fontSize: '2.5rem',
      [theme.breakpoints.down('md')]: {
          fontSize: '1.5rem', 
      },
      [theme.breakpoints.down('600')]: {
          fontSize: '1rem', 
      },
      textShadow: '2px 2px 4px rgba(0, 0, 0, 1)',
  },
  telegramFab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 1000,
    width: '60px', 
    height: '60px',
    backgroundColor: '#0088cc', 
    color: 'white',
    '&:hover': {
        backgroundColor: '#005f8c',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(5),
      right: theme.spacing(2),
    },
  },
  telegramIcon: {
      fontSize: '2.2rem', 
  },
  viberFab: {
    position: 'fixed',
    bottom: theme.spacing(15),
    right: theme.spacing(5),
    zIndex: 1000,
    width: '60px', 
    height: '60px',
    // display: 'flex', justifyContent:'center', alignItems: 'center',
    backgroundColor: '#59267C', 
    color: 'white',
    '&:hover': {
        backgroundColor: '#7B519D',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(15),
      right: theme.spacing(2),
    },
  },
  viberIcon: {
      // fontSize: '2.2rem', 
      width: '35px', 
      height: '35px',
      color: '#fff'
  },
  modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #19335C',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4, 6),
      maxWidth: 500,
      width: '100%',
      borderRadius: 8,
      textAlign: 'center',
      // marginRight: '10px',
      [theme.breakpoints.down('600')]: {
        maxWidth: 400,
      },
      [theme.breakpoints.down('500')]: {
        maxWidth: 320,
      },
  },
  formIcon: {
      width: 50,
      height: 50,
      marginBottom: theme.spacing(2),
  },
  textField: {
      marginBottom: theme.spacing(2),
  },
  buttonContainer: {
      marginTop: theme.spacing(2),
  },
})

export const aboutUsStyles = theme => ({
  aboutSection: {
    padding: `${theme.spacing(10)}px ${theme.spacing(20)}px`,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('1500')]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(10)}px`,
    },
    [theme.breakpoints.down('1200')]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(5)}px`,
    },
    [theme.breakpoints.down('1000')]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(5)}px`,
    },
    [theme.breakpoints.down('800')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(10)}px`,
    },
    [theme.breakpoints.down('600')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`,
    },
  },
  featureBox: {
      display: 'flex',
      alignItems: 'flex-start',
      // alignItems: 'center',
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
      },
  },
  featureIconWrapper: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
          marginBottom: theme.spacing(1),
          marginRight: 0,
      },
  },
  featureIcon: {
      fontSize: '5rem',
      color: '#3F51B5',  // Цвет иконок
  },
  readMoreButton: {
      marginTop: theme.spacing(1),
      background: 'transparent',
      textTransform: 'none',
      fontWeight: 'bold',
      padding: 0,
      color: '#3F51B5',
      '&:hover': {
          background: 'transparent',
          color: 'black'
      }
  },
  aboutUsBlock: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.down('1250')]: {
        alignItems: 'center'
      },
  },
  aboutUsTextContainer: {
    padding: theme.spacing(20),
  },
  aboutUsImage: {
      width: '100%',
      height: 'auto',
  },
  // title: {
  //   color: '#19335C',
  //   position: 'relative',
  //   textTransform: 'uppercase',  
  //   fontWeight: theme.typography.fontWeightBold,  
  //   fontSize: theme.typography.h4.fontSize,
  // },
  titleContainer: {
    position: 'relative',
  },
  title: {
    color: '#19335C',
    paddingBottom: theme.spacing(2),
    position: 'relative',
    textTransform: 'uppercase',  
    fontWeight: theme.typography.fontWeightBold,  
    fontSize: theme.typography.h4.fontSize,
    zIndex: 1,
    textAlign: 'center',
      '&::before': {
          content: '""',
          position: 'absolute',
          display: 'block',
          width: '120px',
          height: '1px',
          background: '#ddd',
          bottom: '1px',
          left: 'calc(50% - 60px)',
      },
      '&::after': {
          content: '""',
          position: 'absolute',
          display: 'block',
          width: '40px',
          height: '3px',
          background: '#47b2e4',
          bottom: '0',
          left: 'calc(50% - 20px)',
      },
  },
  titleBackground: {
      position: 'absolute',
      top: '35%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#000',
      opacity: .05,
      zIndex: 0,
      letterSpacing: 6,
      whiteSpace: 'nowrap',
      textTransform: 'uppercase',  
      fontWeight: theme.typography.fontWeightBold, 
      fontSize: theme.typography.h2.fontSize
  },
  aboutUsDescription: {
    fontSize: '1.2rem',
    [theme.breakpoints.down('1250')]: {
      fontSize: '1rem',
    },
  },
  line: {
    position: 'absolute',
    width: '120px', 
    top: '150%', 
    height: '1px', 
    backgroundColor: '#ddd', 
  },
  widePart: {
    width: '40px', 
    marginLeft: 'calc(50% - 20px)',
    marginTop: '-1px',
    height: '3px', 
    backgroundColor: '#47b2e4', 
  },
})

export const serviceSectionStyles = theme => ({
  servicesSection: {
    backgroundColor: theme.palette.background.default,
    // padding: theme.spacing(4),
    padding: `${theme.spacing(10)}px ${theme.spacing(20)}px`,
    [theme.breakpoints.down('1500')]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(10)}px`,
    },
    [theme.breakpoints.down('1200')]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(5)}px`,
    },
    [theme.breakpoints.down('1000')]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(5)}px`,
    },
    [theme.breakpoints.down('800')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(10)}px`,
    },
    [theme.breakpoints.down('600')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`,
    },
  },
  titleContainer: {
      position: 'relative',
      // marginBottom: theme.spacing(4),
  },
  title: {
    color: '#19335C',
    paddingBottom: theme.spacing(2),
    position: 'relative',
    textTransform: 'uppercase',  
    fontWeight: theme.typography.fontWeightBold,  
    fontSize: theme.typography.h4.fontSize,
    zIndex: 1,
    textAlign: 'center',
      '&::before': {
          content: '""',
          position: 'absolute',
          display: 'block',
          width: '120px',
          height: '1px',
          background: '#ddd',
          bottom: '1px',
          left: 'calc(50% - 60px)',
      },
      '&::after': {
          content: '""',
          position: 'absolute',
          display: 'block',
          width: '40px',
          height: '3px',
          background: '#47b2e4',
          bottom: '0',
          left: 'calc(50% - 20px)',
      },
  },
  titleBackground: {
      position: 'absolute',
      top: '35%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#000',
      opacity: .05,
      zIndex: 0,
      letterSpacing: 6,
      whiteSpace: 'nowrap',
      fontWeight: theme.typography.fontWeightBold, 
      fontSize: theme.typography.h2.fontSize
  },
  serviceLink: {
      textDecoration: 'none',
      color: 'inherit',
      '&:hover': {
            textDecoration: 'none',
      },
  },
  serviceBox: {
      border: '1px solid #DBDDE1',
      // padding: theme.spacing(2),
      // textAlign: 'center',
      overflow: 'hidden',
      '&:hover $serviceTitle': {
          color: '#0026FF', 
      },
      '&:hover $serviceImage': {
          transform: 'scale(1.2)',
      },
  },
  imageWrapper: {
      overflow: 'hidden',
      // height: '200px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%', 
  },
  serviceImage: {
      width: '100%',
      height: '100%',
      transition: 'transform 0.5s ease',
      objectFit: 'cover', 
      // alignSelf: 'center',
  },
  serviceTitle: {
      transition: 'color 0.3s ease',
      fontWeight: 'bold', 
      textTransform: 'uppercase', 
      color: '#19335C'
  },
})

export const callToActionSectionStyles = theme => ({
  parallax: {
      height: '380px',
      display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'center',
      position: 'relative',
  },
  overlay: {
      width: '100vw',
      height: '380px',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      // backgroundColor: '#120049', 
      // opacity: .5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  content: {
      textAlign: 'center',
      color: '#fff',
      // border: '1px solid yellow'
  },
  title: {
      marginBottom: theme.spacing(2),
      textTransform: 'uppercase', 
  },
  subtitle: {
      // width: '30%',
      marginBottom: theme.spacing(4),
  },
  // button: {
  //     fontSize: '1rem',
  // },
  callButton: {
      transform: 'translateX(0%)', 
      backgroundColor: '#1976d2', 
      color: '#fff',
      padding: '12px 24px',
      fontSize: '1rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      borderRadius: '10px', 
      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)',
      transition: 'all 0.3s ease',
      '&:hover': {
          backgroundColor: '#115293', 
          boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)', 
          transform: 'translateX(0%) translateY(-2px)', 
      },
      '&:active': {
          backgroundColor: '#0d47a1', 
          boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)', 
          transform: 'translateX(0%) translateY(0)', 
      },
      [theme.breakpoints.down('sm')]: {
          marginLeft: 0,
      },
     
  },
})

export const advantagesSectionStyles = theme => ({
  advantagesSection: {
      // padding: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    // padding: theme.spacing(4),
    padding: `${theme.spacing(10)}px ${theme.spacing(20)}px`,
    [theme.breakpoints.down('1500')]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(10)}px`,
    },
    [theme.breakpoints.down('1200')]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(5)}px`,
    },
    [theme.breakpoints.down('1000')]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(5)}px`,
    },
    [theme.breakpoints.down('800')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(10)}px`,
    },
    [theme.breakpoints.down('600')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`,
    },
  },
  advantage: {
      marginBottom: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-beetween',
      [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
      },
  },
  imageContainer: {
      // textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
          order: 1,
      },
  },
  image: {
      width: '100%',
      height: 'auto',
      maxWidth: '550px',
      transition: 'transform 0.5s ease',
  },
  textContainer: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
      },
  },
  title: {
      marginBottom: theme.spacing(2),
      color: '#19335C',
      // fontWeight: 600
  },
  shortDescription: {
      fontStyle: 'italic',
      marginBottom: theme.spacing(1),
      color: '#19335C',
      fontWeight: 600,
      opacity: .8
  },
  description: {
      marginBottom: theme.spacing(2),
  },
  link: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      marginTop: theme.spacing(1),
      display: 'block',
      '&:hover': {
          textDecoration: 'underline',
      },
  },
})

export const faqSectionStyles = theme => ({
  faqSection: {
    backgroundColor: theme.palette.background.default,
    padding: `${theme.spacing(10)}px ${theme.spacing(20)}px`,
    overflow: 'hidden', 
    [theme.breakpoints.down('1500')]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(10)}px`,
    },
    [theme.breakpoints.down('1200')]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(5)}px`,
    },
    [theme.breakpoints.down('1000')]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(5)}px`,
    },
    [theme.breakpoints.down('800')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(10)}px`,
    },
    [theme.breakpoints.down('600')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`,
    },

  },
  titleContainer: {
      position: 'relative',
      // marginBottom: theme.spacing(4),
  },
  title: {
    color: '#19335C',
    paddingBottom: theme.spacing(2),
    position: 'relative',
    textTransform: 'uppercase',  
    fontWeight: theme.typography.fontWeightBold,  
    fontSize: theme.typography.h4.fontSize,
    zIndex: 1,
    textAlign: 'center',
      '&::before': {
          content: '""',
          position: 'absolute',
          display: 'block',
          width: '120px',
          height: '1px',
          background: '#ddd',
          bottom: '1px',
          left: 'calc(50% - 60px)',
      },
      '&::after': {
          content: '""',
          position: 'absolute',
          display: 'block',
          width: '40px',
          height: '3px',
          background: '#47b2e4',
          bottom: '0',
          left: 'calc(50% - 20px)',
      },
  },
  titleBackground: {
      position: 'absolute',
      top: '35%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#000',
      opacity: .05,
      zIndex: 0,
      letterSpacing: 6,
      whiteSpace: 'nowrap',
      fontWeight: theme.typography.fontWeightBold, 
      fontSize: theme.typography.h2.fontSize
  },
  sectionTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  accordion: {
    backgroundColor: '#E5E5E5',
    padding: '10px 0',
    marginTop: theme.spacing(2),
    // boxShadow: 'none',
    // borderBottom: '1px solid #ddd',
  },
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
      fontWeight: 'bold',
    },
  },
  accordionSummaryExpanded: {
    color: '#0D42FF', 
  },
  accordionDetails: {
    // padding: theme.spacing(2, 3), 
  },
  questionText: {
    fontWeight: 'bold',
  },
  answerText: {
    // fontStyle: 'italic', 
    fontSize: '1.1rem',
  },
  helpIcon: {
    marginRight: theme.spacing(2),
    color: '#0D42FF'
  },
})

export const footerStyles = theme => ({
  footer: {
    backgroundColor: '#19335C',
    // backgroundColor: '#3F51B5',
    color: '#fff',
    padding: theme.spacing(4, 0),
    [theme.breakpoints.down('1500')]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(10)}px`,
    },
    [theme.breakpoints.down('1200')]: {
      padding: `${theme.spacing(10)}px ${theme.spacing(5)}px`,
    },
    [theme.breakpoints.down('1000')]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(5)}px`,
    },
    [theme.breakpoints.down('800')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(10)}px`,
    },
    [theme.breakpoints.down('600')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`,
    },
  },
  logo: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  slogan: {
    marginBottom: theme.spacing(2),
  },
  socialIcons: {
    marginTop: theme.spacing(2),
  },
  socialIcon: {
    color: '#fff',
    marginRight: theme.spacing(1),
    '&:hover': {
      color: '#47b2e4',
    },
  },
  columnTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    textAlign: 'left',
  },
  list: {
    padding: 0,
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      '&:hover': {
        color: '#47b2e4',
      },
    },
    // border: '1px solid yellow'
  },
  listItem: {
    paddingLeft: 0
  },
  contactInfo: {
    marginTop: theme.spacing(2),
  },
  footerBottom: {
    // backgroundColor: '#1b1b1b',
    padding: theme.spacing(2, 0),
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  developedBy: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    color: '#fff',

    '& img': {
      marginRight: theme.spacing(0.5),
      width: '18px', 
      height: '18px',
      color: '#47b2e4',

    },
  },
  pratsdevLink: {
    color: '#47b2e4',
    textDecoration: 'none',
    display:'flex',
    flexDirection: 'row',
    width: '20px', height: '20px',
  }
})

// ---------------------------------------------------- //

export const headerLevel2styles = theme => ({
  header: {
    width: '100%',
    height: '45vh',
    backgroundImage: `url(${headerLevel2Background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
  appBar: {
    background: 'transparent',
    boxShadow: 'none',
    transition: 'background 0.3s',
  },
  appBarScrolled: {
    background: '#3F51B5',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    marginRight: theme.spacing(2),
    height: 30,
  },
  logoLink: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
  },
  navLinks: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  tabs: {
    minWidth: 'auto',
  },
  tab: {
    minWidth: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('1350')]: {
      marginLeft: theme.spacing(0.5), 
      marginRight: theme.spacing(0.5),
    }
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  navigation: {
    height: '60px',
    backgroundColor: '#F3F6FC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: `0 ${theme.spacing(20)}px`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawerPaper: {
    backgroundColor: '#f5f5f5', 
    opacity: .9
  },
  drawerList: {
    width: 250,
  },
  activeText: {
    // fontWeight: 'bold',
    color: 'blue'
  },
  inactiveText: {
    // fontWeight: 500
  },
  tempBlock: {
    height: '2000px',
    backgroundColor: 'white',
  },
})

export const menuPanelstyles = theme => ({

})

export const navPanelstyles = theme => ({
  navigation: {
    height: '60px',
    backgroundColor: '#F3F6FC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: `0 ${theme.spacing(20)}px`,
    borderBottom: '0.5px solid #EAF0F7',
    [theme.breakpoints.down('800')]: {
      padding: `0 ${theme.spacing(10)}px`,
    },
    [theme.breakpoints.down('600')]: {
      padding: `0 ${theme.spacing(5)}px`,
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
})

export const telegramFabStyles = theme => ({
  telegramFab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 1000,
    width: '60px', 
    height: '60px',
    backgroundColor: '#0088cc', 
    color: 'white',
    '&:hover': {
        backgroundColor: '#005f8c',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(5),
      right: theme.spacing(2),
    },
    // [theme.breakpoints.down('500')]: {
    //   bottom: theme.spacing(25),
    //   right: theme.spacing(12),
    // },
  },
  telegramIcon: {
      fontSize: '2.2rem', 
  },
})

export const viberFabStyles = theme => ({
  viberFab: {
    position: 'fixed',
    bottom: theme.spacing(15),
    right: theme.spacing(5),
    zIndex: 1000,
    width: '60px', 
    height: '60px',
    // display: 'flex', justifyContent:'center', alignItems: 'center',
    backgroundColor: '#59267C', 
    color: 'white',
    '&:hover': {
        backgroundColor: '#7B519D',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(15),
      right: theme.spacing(2),
    },
  },
  viberIcon: {
      // fontSize: '2.2rem', 
      width: '35px', 
      height: '35px',
      color: '#fff'
  },
})

export const consultationModalStyles = theme => ({
  modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #19335C',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4, 6),
      maxWidth: 500,
      width: '100%',
      borderRadius: 8,
      textAlign: 'center',
      [theme.breakpoints.down('600')]: {
        maxWidth: 400,
      },
      [theme.breakpoints.down('500')]: {
        maxWidth: 320,
      },
  },
  formIcon: {
      width: 50,
      height: 50,
      marginBottom: theme.spacing(2),
  },
  textField: {
      marginBottom: theme.spacing(2),
  },
  buttonContainer: {
      marginTop: theme.spacing(2),
  },
  textField: {
      textAlign: 'left', 
  },
})

// ---------------------------------------------------- //

export const serviceDetailsScreenStyles = theme => ({

})


 
  



  