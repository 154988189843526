import React, { useEffect, useState, useRef } from 'react';

import { 
    Box, 
    Grid, 
    Typography, 
    useTheme, 
    Link,
    Snackbar,
    IconButton,
    Button
} from '@material-ui/core';

import { Parallax } from 'react-parallax';

import CloseIcon from '@material-ui/icons/Close';

import { withStyles } from '@material-ui/core/styles';
import { callToActionSectionStyles } from '../../../themes/theme-white';

import ConsultationModal from '../../../components/elements/ConsultationModal';

import config from '../../../config/config';

import backgroundImage from '../../../assets/cta/cta-bg3.jpg';

function CallToActionSection({classes}) {

    const theme = useTheme();
    const [modalOpen, setModalOpen] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleFormSubmit = async (userData) => {

        const { name, contactType, email, phone, question } = userData

        const contactTitle  = email ? `Email` : `Телефон`
        const contact  = email ? email : phone

        const corpEmail = config.devMode ? 'alexandr.inflexible@gmail.com' : config.contacts.email

        const response = await fetch(`${config.hosting.root}/send_email.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({ formType: 'consultation', name, contactTitle, contact, question, corpEmail}),
        });

        const result = await response.json();

        if (result.status === 'success') {
            setSnackbarMessage('Повідомлення успішно відправлено!');
            setSnackbarType('success');
            handleCloseModal();
        } else {
            setSnackbarMessage('Помилка при надсиланні повідомлення! Будь ласка, спробуйте ще раз!');
            setSnackbarType('error');
        }

        setSnackbarOpen(true);
        handleCloseModal();

    };

    return (
        <Parallax bgImage={backgroundImage} strength={500} className={classes.parallax}>
            <Box className={classes.overlay}>
                {/* <Box className={classes.overlay} /> */}
                <Box className={classes.content}>
                    <Typography variant="h3" className={classes.title}>
                        отримати консультацію
                    </Typography>
                    <Typography variant="body1" className={classes.subtitle}>
                        Фахівці нашої компанії дадуть відповідь на всі ваші запитання
                    </Typography>
                    {/* <Typography variant="body1" className={classes.subtitle}>
                    {`+380 (67) 876-54-32, +380 (93) 876-54-32, +380 (50) 876-54-32`}
                    </Typography> */}
                    {/* <Button variant="contained" color="primary" className={classes.callButton}>
                        отримати консультацію
                    </Button> */}
                    <Button 
                        variant="contained" 
                        color="primary" 
                        className={classes.callButton}
                        onClick={handleOpenModal}
                    >
                        отримати консультацію
                    </Button>
                </Box>
            </Box>
            <ConsultationModal 
                modalOpen={modalOpen} 
                handleCloseModal={handleCloseModal} 
                handleFormSubmit={handleFormSubmit} 
            />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </Parallax>
    )
}

export default withStyles(callToActionSectionStyles)(CallToActionSection)

