import React, { useEffect, useState, useRef } from 'react';

import {
    AppBar,
    Toolbar,
    Typography,
    Tabs,
    Tab,
    Box,
    makeStyles,
    useTheme,
    useMediaQuery,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Button,
    ListItemIcon,
    Fab,
    Modal,
    Backdrop,
    Fade,
    TextField,
    Grid,
    Snackbar,
} from '@material-ui/core';

import Slider from 'react-slick';

import { SvgIcon } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

import AOS from 'aos';

import { withStyles } from '@material-ui/core/styles';
import { sliderStyles } from '../../../themes/theme-white';

import backgroundImage from '../../../assets/slider/slider-background.jpg';
import TelegramIcon from '@material-ui/icons/Telegram';
import ViberIcon from '@material-ui/icons/Phone';

import { ReactComponent as ViberSvg } from '../../../assets/social/viber-icon.svg';

import ContactMailIcon from '@material-ui/icons/ContactMail';

import config from '../../../config/config';

function SliderSection({classes}) {

    const theme = useTheme();
    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    const slides = [
        { title: 'Доставка Вантажів', description: 'професійний підхід до кожного кілометру' },
        { title: 'Міжнародні перевезення', description: 'швидко, якісно, надійно' },
        { title: 'Митне оформлення', description: 'ефективність і безпека кожної операції' },
        { title: 'Страхування вантажів', description: 'ваш спокій - наша відповідальність' },
        { title: 'Складування та дистрибуція', description: 'оптимізація логістики для вашого бізнесу' },
    ];

    const fixedBlockItems = [
        { value: '232', label: 'Клієнтів' },
        { value: '1000+', label: 'Проектів' },
        { value: '1467', label: 'Співпраць' },
        { value: '21', label: 'Працівників' },
    ];

    // const fixedBlockItems = [
    //     { value: '232', label: 'Clients' },
    //     { value: '1000+', label: 'Projects' },
    //     { value: '1467', label: 'Support' },
    //     { value: '21', label: 'Workers' },
    // ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const corpEmail = config.devMode ? 'alexandr.inflexible@gmail.com' : config.contacts.email

        const response = await fetch(`${config.hosting.root}/send_email.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({ formType: 'callback', name, phone, corpEmail }),
        });

        const result = await response.json();

        if (result.status === 'success') {
            setSnackbarMessage('Повідомлення успішно відправлено!');
            setSnackbarType('success');
            handleCloseModal();
        } else {
            setSnackbarMessage('Помилка при надсиланні повідомлення! Будь ласка, спробуйте ще раз!');
            setSnackbarType('error');
        }

        setSnackbarOpen(true);
        handleCloseModal();
        

    };
    return (
        <Box className={
            classes.sliderContainer
        }>
            <Slider {...settings} className={classes.slider}>
                {
                    slides.map((slide, index) => (
                        <Box key={index} p={3} textAlign="center" className={classes.slideContainer} data-aos="fade-up"> 
                            <Typography variant="h2" className={classes.sliderTitle}>
                            {slide.title}
                            </Typography>
                            <Typography variant="h5" className={classes.sliderDescription}>
                            {slide.description}
                            </Typography>
                            {/* <Button variant="contained" className={classes.callButton}>
                            Замовити дзвінок
                            </Button> */}
                        </Box>
                    ))
                }
            </Slider>
            {/* <Button variant="contained" className={classes.callButton}>
                Замовити дзвінок
            </Button> */}
           <Box className={classes.bottomLeftBlock}>
                <Box style={{display: 'flex', flexDirection: 'row'}} data-aos="fade-up" data-aos-delay={200}>
                    {fixedBlockItems.map((item, index) => (
                        <Box key={index} className={classes.blockItem}>
                            <Typography variant="h6" className={classes.blockValue}>{item.value}</Typography>
                            <div className={classes.separator} />
                            <Typography variant="caption" className={classes.blockLabel}>{item.label}</Typography>
                        </Box>
                    ))}
                </Box>
                <Box className={classes.callButtonContainer} data-aos="fade-up" data-aos-delay={200}>
                    <Button variant="contained" className={classes.callButton} onClick={handleOpenModal}>
                        Замовити дзвінок
                    </Button>
                </Box>
            </Box>

            {/* {
                config.elements.showViberBotButton &&
                    <Fab
                        className={classes.viberFab}
                        href="viber://chat?number=%2B123456789" 
                        target="_blank"
                        aria-label="Viber"
                        >
                            <SvgIcon className={classes.viberIcon} component={ViberSvg}  />
                    </Fab>
            }

            {
                config.elements.showTelegramBotButton &&
                    <Fab
                        href="https://t.me/JustDontGiveUp" 
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.telegramFab}
                    >
                        <TelegramIcon className={classes.telegramIcon} />
                    </Fab>
            } */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalOpen}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalOpen}>
                    <div className={classes.paper}>
                        <SvgIcon className={classes.formIcon}>
                            <ContactMailIcon style={{ fontSize: 50, color: '#19335C' }} />
                        </SvgIcon>
                        <Typography variant="h6" id="transition-modal-title" gutterBottom style={{color: '#19335C' }}>
                            Замовити дзвінок
                        </Typography>
                        <form onSubmit={handleFormSubmit}>
                            <TextField
                                label="Ім'я"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                className={classes.textField}
                            />
                            <TextField
                                label="Номер телефону"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                                className={classes.textField}
                            />
                            <Grid container justifyContent="flex-end" className={classes.buttonContainer}>
                                <Button type="submit" variant="contained" color="primary">
                                    Відправити
                                </Button>
                            </Grid>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </Box>
    )
}

export default withStyles(sliderStyles)(SliderSection)

