import React, { useEffect, useState, useRef } from 'react';

import { 
    Box,
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useTheme, 
} from '@material-ui/core';

import AOS from 'aos';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { withStyles } from '@material-ui/core/styles';
import { faqSectionStyles } from '../../../themes/theme-white';

import config from '../../../config/config';

const faqs = [
    {
      question: 'Які документи необхідні для міжнародного автоперевезення?',
      answer: 'Для міжнародних автоперевезень потрібні транспортні документи (CMR, накладна), митні декларації та інші супровідні документи в залежності від типу вантажу.',
    },
    {
      question: 'Чи можу я відстежувати свій вантаж?',
      answer: 'Так, наша система відстеження дозволяє клієнтам бачити точне місцезнаходження їхнього вантажу в будь-який момент часу.',
    },
    {
      question: 'Чи є обмеження по типу вантажу, який ви перевозите?',
      answer: 'Ми перевозимо широкий спектр вантажів, включаючи небезпечні та великогабаритні вантажі. Якщо у вас є специфічний вантаж, будь ласка, зв\'яжіться з нами для консультації.',
    },
];


function FaqSection({classes, isMain = true}) {
    console.log('isMain: ', isMain);

    const theme = useTheme();

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <Box className={classes.faqSection}>
            <Container>
                {/* <Typography variant="h4" className={classes.sectionTitle}>
                    Frequently Asked Questions
                </Typography> */}
                {
                    isMain ? 
                        <Box className={classes.titleContainer}>
                            <Typography variant="h4" className={classes.title}>ПОШИРЕНІ ПИТАННЯ</Typography>
                            <Typography variant="h1" className={classes.titleBackground}>ПОШИРЕНІ ПИТАННЯ</Typography>
                        </Box>
                    :
                        <Box className={classes.titleContainer} data-aos="fade-up">
                            <Typography variant="h4" className={classes.title}>ПОШИРЕНІ ПИТАННЯ</Typography>
                            <Typography variant="h1" className={classes.titleBackground}>ПОШИРЕНІ ПИТАННЯ</Typography>
                        </Box>
                }

                {
                    isMain ? 
                        <Box style={{marginTop: '50px'}}>
                            {faqs.map((faq, index) => (
                                <Accordion key={index} className={classes.accordion}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        className={classes.accordionSummary}
                                        classes={{ expanded: classes.accordionSummaryExpanded }}
                                    >
                                        <HelpOutlineIcon className={classes.helpIcon} />
                                        <Typography className={classes.questionText}>{faq.question}</Typography>
                                    </AccordionSummary>
                                        <AccordionDetails className={classes.accordionDetails}>
                                        <Typography className={classes.answerText}>{faq.answer}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}

                        </Box>
                    :
                        <Box style={{marginTop: '50px'}} data-aos="fade-up" data-aos-delay={200}>
                            {faqs.map((faq, index) => (
                                <Accordion key={index} className={classes.accordion}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        className={classes.accordionSummary}
                                        classes={{ expanded: classes.accordionSummaryExpanded }}
                                    >
                                        <HelpOutlineIcon className={classes.helpIcon} />
                                        <Typography className={classes.questionText}>{faq.question}</Typography>
                                    </AccordionSummary>
                                        <AccordionDetails className={classes.accordionDetails}>
                                        <Typography className={classes.answerText}>{faq.answer}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}

                        </Box>
                }

            </Container>
        </Box>

    )
}

export default withStyles(faqSectionStyles)(FaqSection)

