import React, { useEffect, useState, useRef } from 'react';

import {
    AppBar,
    Toolbar,
    Typography,
    Tabs,
    Tab,
    Box,
    makeStyles,
    useTheme,
    useMediaQuery,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Divider,
    Button,
    ListItemIcon
} from '@material-ui/core';

import { Link } from 'react-router-dom';

import MenuIcon from '@material-ui/icons/Menu';
import PhoneIcon from '@material-ui/icons/Phone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { withStyles } from '@material-ui/core/styles';
import { headerLevel2styles } from '../../themes/theme-white';

import logo from '../../assets/logo/logo4.png'; 
import uaFlag from '../../assets/flags/ua.png'; 
import engFlag from '../../assets/flags/eng.png'; 
import plFlag from '../../assets/flags/pl.png'; 

import backgroundImage from '../../assets/service/header.jpg'; 

import { categories } from '../../config/myConstants';

import config from '../../config/config';

const phoneNumbers = config.contacts.phones
const appName = config.appName
const languages = config.languages

function CustomHeaderLevel2({classes, activeCategory}) {

    const theme = useTheme();
	  const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
    const drawerRef = useRef(null);

    const [scrolled, setScrolled] = useState(false);

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [currentLanguage, setCurrentLanguage] = useState(languages[0]);

    useEffect(() => {
      const handleScroll = () => {
        setScrolled(window.scrollY > 100);
      };
  
      window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll);

    }, []);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    }
    
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }
    

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleLanguageMenuOpen = (event) => {
      setLanguageAnchorEl(event.currentTarget);
    };
    
    const handleLanguageMenuClose = () => {
      setLanguageAnchorEl(null);
    };

    const handleLanguageChange = (language) => {
      setCurrentLanguage(language);
      handleLanguageMenuClose(); // Закрыть меню после выбора языка
    };


    useEffect(() => {
      if (!drawerRef?.current) return
      const input = drawerRef.current;
      input.select();
    }, []);

    return (
      <Box>
        <AppBar className={`${classes.appBar} ${scrolled ? classes.appBarScrolled : ''}`}>
          <Toolbar className={classes.toolbar}>

            <Box display="flex" alignItems="center">
                <a href="/" className={classes.logoLink}>
                    <img src={logo} alt="Logo" className={classes.logo} />
                    <Typography variant="h6">{appName}</Typography>
                </a>
            </Box>

            { 
              isMobile ? (
                <>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={handleDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Drawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={handleDrawerToggle}
                    classes={{ paper: classes.drawerPaper }}
                  >
                    <List className={classes.drawerList}>

                      {categories.map((category, index) => (
                        <Link key={index} to={category.path} className={classes.link}>
                          <ListItem button key={index} >
                            <ListItemText 
                              primary={category.label} 
                              className={category.label === activeCategory ? classes.activeText : classes.inactiveText}
                            />
                          </ListItem>
                        </Link>
                      ))}

                      <Divider />

                      {phoneNumbers.map((number, index) => (
                        <ListItem key={index}>
                          <PhoneIcon />
                          <ListItemText primary={number} />
                        </ListItem>
                      ))}


                    </List>

                  </Drawer>
                </>
              ) : (
                <>
                  <Tabs value={false} className={classes.tabs}>
                    {categories.map((category, index) => (
                      <Link key={index} to={category.path} className={classes.link}>
                        <Tab 
                          label={category.label} 
                          className={classes.tab} 
                          style={{opacity: category.label === activeCategory ? .6 : 1}} 
                          // style={{color: category.label === activeCategory ? 'blue' : '#fff'}} 
                          // style={{fontWeight: category.label === activeCategory ? '600' : '500'}} 
                        />
                      </Link>
                    ))}
                  </Tabs>
                </>
              )
            }
          </Toolbar>
        </AppBar>


        <Box className={classes.header} />
        
      </Box>
    )
}

export default withStyles(headerLevel2styles)(CustomHeaderLevel2)