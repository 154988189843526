import React, { useEffect, useState, useRef } from 'react';

import {
	AppBar,
	Toolbar,
	Typography,
	Tabs,
	Tab,
	Box,
	makeStyles,
	useTheme,
	useMediaQuery,
	IconButton,
	Drawer,
	List,
	ListItem,
	ListItemText,
	Grid, 
	Link
} from '@material-ui/core';


import CustomHeader from '../../components/elements/CustomHeader';
import CustomHeaderLevel2 from '../../components/elements/CustomHeaderLevel2';
import NavPanel from '../../components/elements/NavPanel';

import ServicesSection from '../HomeScreen/components/ServicesSection';

import CustomFooter from '../../components/elements/CustomFooter';

import { withStyles } from '@material-ui/core/styles';

import { homeStyles } from '../../themes/theme-white';

import serviceImage from '../../assets/service/service-details1.jpg';

import { services } from '../../config/myConstants';

function PackagingDetailedScreen({classes, activeCategory = services[3].title}) {

	const theme = useTheme();
	const localStyles = useStyles();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (

		<div className={classes.root}>

            <CustomHeaderLevel2 activeCategory='' />

			<NavPanel navRoute={['Деталі послуги']} />

			<Box className={localStyles.root}>
				<Grid container spacing={4} style={{justifyContent: 'space-between'}}>
					<Grid item xs={12} md={4}>
						<Box className={localStyles.categoryContainer}>
							<Box className={activeCategory === services[0].title ? localStyles.categoryItemActive : localStyles.categoryItem}>
								<Link href={services[0].link} className={localStyles.categoryLink} style={{fontWeight: activeCategory === 1 ? 600 : 500}}>
									{/* <Box className={`${localStyles.leftBorder} ${localStyles.active}`} /> */}
									Автоперевезення
								</Link>
							</Box>
							<Box className={activeCategory === services[1].title ? localStyles.categoryItemActive : localStyles.categoryItem} style={{marginTop: '15px'}}>
								<Link href={services[1].link} className={localStyles.categoryLink} style={{fontWeight: activeCategory === 2 ? 600 : 500}}>
									Зберігання
								</Link>
							</Box>
							<Box className={activeCategory === services[2].title ? localStyles.categoryItemActive : localStyles.categoryItem} style={{marginTop: '15px'}}>
								<Link href={services[2].link} className={localStyles.categoryLink} style={{fontWeight: activeCategory === 3 ? 600 : 500}}>
									Логістика
								</Link>
							</Box>
							<Box className={activeCategory === services[3].title ? localStyles.categoryItemActive : localStyles.categoryItem} style={{marginTop: '15px'}}>
								<Link href={services[3].link} className={localStyles.categoryLink} style={{fontWeight: activeCategory === 4 ? 600 : 500}}>
									Пакування
								</Link>
							</Box>
							<Box className={activeCategory === services[4].title ? localStyles.categoryItemActive : localStyles.categoryItem} style={{marginTop: '15px'}}>
								<Link href={services[4].link} className={localStyles.categoryLink} style={{fontWeight: activeCategory === 5 ? 600 : 500}}>
									Складування
								</Link>
							</Box>
							<Box className={activeCategory === services[5].title ? localStyles.categoryItemActive : localStyles.categoryItem} style={{marginTop: '15px'}}>
								<Link href={services[5].link} className={localStyles.categoryLink} style={{fontWeight: activeCategory === 6 ? 600 : 500}}>
									Страхування
								</Link>
							</Box>
						</Box>
						<Box className={localStyles.descriptionContainer}>
							<Typography variant="h6" className={localStyles.descriptionTitle}>
								Enim qui eos rerum in delectus
							</Typography>
							<Typography variant="body1" className={localStyles.descriptionText}>
								Nam voluptatem quasi numquam quas fugiat ex temporibus quo est. Quia aut quam quod facere ut non occaecati ut aut. Nesciunt mollitia illum tempore corrupti sed eum reiciendis. Maxime modi rerum.
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} md={7} >
						<img src={serviceImage} alt="Service" className={localStyles.serviceImage} />
						<Typography variant="h4" className={localStyles.serviceTitle}>
						Temporibus et in vero dicta aut eius lidero plastis trand lined voluptas dolorem ut voluptas
						</Typography>
						<Typography variant="body1" className={localStyles.serviceDescription}>
						<br />Blanditiis voluptate odit ex error ea sed officiis deserunt. Cupiditate non consequatur et doloremque consequuntur. Accusantium labore reprehenderit error temporibus saepe perferendis fuga doloribus vero. Qui omnis quo sit. Dolorem architecto eum et quos deleniti officia qui.
						</Typography>
						<Typography variant="body1" className={localStyles.serviceDescription}>
						<br />Aut eum totam accusantium voluptatem. Assumenda et porro nisi nihil nesciunt voluptatibus. Ullamco laboris nisi ut aliquip ex eapariatur itaque nesciunt.
						</Typography>
						<Typography variant="body1" className={localStyles.serviceDescription}>
						<br />Est reprehenderit voluptatem necessitatibus asperiores neque sed ea illo. Deleniti quam sequi optio iste veniam repellat odit. Aut pariatur itaque nesciunt fuga. Sunt rem odit accusantium omnis perspiciatis officia. Laboriosam aut consequuntur recusandae mollitia doloremque est architecto cupiditate ullam. Quia est ut occaecati fuga. Distinctio ex repellendus eveniet velit sint quia sapiente cumque. Et ipsa perferendis ut nihil. Laboriosam vel voluptates tenetur nostrum. Eaque iusto cupiditate et totam et quia dolorum in. Sunt molestiae ipsum at consequatur vero. Architecto ut pariatur autem ad non cumque nesciunt qui maxime. Sunt eum quia impedit dolore alias explicabo ea. 
						</Typography>
					</Grid>

				</Grid>
			</Box>

			<CustomFooter />
			
        </div>
    
	)

}


const useStyles = makeStyles((theme) => ({
    root: {
        padding: `${theme.spacing(10)}px ${theme.spacing(20)}px`,
		[theme.breakpoints.down('1500')]: {
			padding: `${theme.spacing(10)}px ${theme.spacing(10)}px`,
		},
		[theme.breakpoints.down('1200')]: {
			padding: `${theme.spacing(10)}px ${theme.spacing(5)}px`,
		},
		[theme.breakpoints.down('1000')]: {
			padding: `${theme.spacing(8)}px ${theme.spacing(5)}px`,
		},
		[theme.breakpoints.down('800')]: {
			padding: `${theme.spacing(5)}px ${theme.spacing(10)}px`,
		},
		[theme.breakpoints.down('600')]: {
			padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`,
		},
    },
    categoryContainer: {
        border: `1px solid #D3DFF3`,
        // border: `1px solid ${theme.palette.primary.light}`,
        // borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
		paddingLeft: theme.spacing(4)
    },
    categoryItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
		paddingLeft: '10px',
		borderLeft: '3px solid #C2CBDF',
		'&:hover': {
			borderLeft: '3px solid #0D42FF',
		}
    },
    categoryItemActive: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
		paddingLeft: '10px',
		borderLeft: '3px solid #0D42FF',
    },
    categoryLink: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        flexGrow: 1,
        padding: theme.spacing(1),
		color: '#0E1D34',
		'&:hover': {
			textDecoration: 'none',
		}
    },
    descriptionContainer: {
        marginTop: theme.spacing(2),
    },
    descriptionTitle: {
        marginBottom: theme.spacing(1),
		color: '#0E1D34'
    },
    descriptionText: {
        // color: theme.palette.text.secondary,
		color: '#0E1D34'
    },
    serviceImage: {
        width: '100%',
        height: 'auto',
        // borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2),
    },
    serviceTitle: {
        marginBottom: theme.spacing(1),
		color: '#0E1D34'
    },
    serviceDescription: {
        // color: theme.palette.text.secondary,
		color: '#0E1D34'
    },
}));

export default withStyles(homeStyles)(PackagingDetailedScreen)