import React, { useEffect, useState, useRef } from 'react';

import { 
    Box,
    Container,
    Typography,
    List,
    ListItem,
    ListItemText,
    Link,
    IconButton,
    Grid,
    useTheme, 
} from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';

import pratsdevIcon from '../../assets/pratsdev/pratsdev1.png'

import { withStyles } from '@material-ui/core/styles';
import { footerStyles } from '../../themes/theme-white';

import config from '../../config/config';

const contactPhones = config.contacts.phones
const email = config.contacts.email

const appName = config.appName

function CustomFooter({classes}) {

    const theme = useTheme();

    return (
        <Box className={classes.footer}>
            <Container>
                <Grid container spacing={4}>
                {/* Первая колонка */}
                <Grid item xs={12} md={4}>
                    <div className={classes.logo}>
                    <Typography variant="h5">{appName}</Typography>
                    </div>
                    <Typography className={classes.slogan}>
                        Ваш надійний партнер з логістики
                        {/* Your trusted logistics partner */}
                    </Typography>
                    <div className={classes.socialIcons}>
                    <IconButton
                        href="https://facebook.com"
                        target="_blank"
                        className={classes.socialIcon}
                    >
                        <FacebookIcon />
                    </IconButton>
                    <IconButton
                        href="https://twitter.com"
                        target="_blank"
                        className={classes.socialIcon}
                    >
                        <TwitterIcon />
                    </IconButton>
                    <IconButton
                        href="https://instagram.com"
                        target="_blank"
                        className={classes.socialIcon}
                    >
                        <InstagramIcon />
                    </IconButton>
                    <IconButton
                        href="https://linkedin.com"
                        target="_blank"
                        className={classes.socialIcon}
                    >
                        <LinkedInIcon />
                    </IconButton>
                    </div>
                </Grid>

                {/* Useful Links */}
                <Grid item xs={12} md={2}>
                    <Typography className={classes.columnTitle}>
                        Меню
                    </Typography>
                    <List className={classes.list}>
                        <ListItem className={classes.listItem}>
                            <Link href="/">Головна</Link>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <Link href="/about">Про Нас</Link>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <Link href="/services">Послуги</Link>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <Link href="/terms">Поширені Питання</Link>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <Link href="/privacy">Контакти</Link>
                        </ListItem>
                    </List>
                </Grid>

                {/*  Our Services */}
                <Grid item xs={12} md={2}>
                    <Typography className={classes.columnTitle}>
                        Послуги
                        {/* Our Services */}
                    </Typography>
                    <List className={classes.list}>
                        <ListItem className={classes.listItem}>
                            <Link href="/services/web-design">Автоперевезення</Link>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <Link href="/services/web-development">Зберігання</Link>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <Link href="/services/product-management">Логістика</Link>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <Link href="/services/graphic-design">Складування</Link>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <Link href="/services/marketing">Пакування</Link>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <Link href="/services/marketing">Страхування</Link>
                        </ListItem>
                    
                    </List>
                </Grid>

                {/* Contact Us */}
                <Grid item xs={12} md={3}>
                    <Typography className={classes.columnTitle}>
                        Адрес
                    </Typography>
                    <div className={classes.contactInfo}>
                    <Typography>Україна, Київ, вулиця Кільцева дорога, 14-в, 03134</Typography>
                    <Typography>Телефон: {contactPhones[0]}</Typography>
                    <Typography>Email: {email}</Typography>
                    </div>
                </Grid>
                </Grid>
            </Container>

            {/* Нижняя часть футера */}
            <Box className={classes.footerBottom}>

                <Typography variant="body2">
                    © Copyright <span style={{fontWeight: 'bold'}}>Logis</span> 2024. All Rights Reserved
                </Typography>

                <div className={classes.developedBy}>
                    
                    <Typography style={{marginLeft: '-60px'}}>
                        Developed by 
                    </Typography>
                    
                    {/* <Link href="https://example.com" target="_blank" className={classes.pratsdevLink}>
                        <img src={pratsdevIcon} alt="PratsDev" style={{marginLeft: '10px'}}/>
                        <Typography variant="body2">
                            PratsDev
                        </Typography>
                        
                    </Link> */}

                    <Box className={classes.pratsdevLink}>
                        <img src={pratsdevIcon} alt="PratsDev" style={{marginLeft: '10px'}}/>
                        <Typography variant="body2">
                            PratsDev
                        </Typography>
                    </Box>

                </div>

            </Box>
        </Box>

    )
}

export default withStyles(footerStyles)(CustomFooter)

