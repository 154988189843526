import React, { useEffect, useState, useRef } from 'react';

import {
    AppBar,
    Toolbar,
    Typography,
    Tabs,
    Tab,
    Box,
    makeStyles,
    useTheme,
    useMediaQuery,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Divider,
    Button,
    ListItemIcon
} from '@material-ui/core';

import { Link } from 'react-router-dom';

import MenuIcon from '@material-ui/icons/Menu';
import PhoneIcon from '@material-ui/icons/Phone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { withStyles } from '@material-ui/core/styles';
import { headerStyles } from '../../themes/theme-white';

import logo from '../../assets/logo/logo4.png'; 
import uaFlag from '../../assets/flags/ua.png'; 
import engFlag from '../../assets/flags/eng.png'; 
import plFlag from '../../assets/flags/pl.png'; 

import { categories } from '../../config/myConstants';

import config from '../../config/config';
const phoneNumbers = config.contacts.phones
const appName = config.appName
const languages = config.languages

function CustomHeader({classes, onMenuClick, sections}) {

    const theme = useTheme();
	  const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
    const drawerRef = useRef(null);

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [currentLanguage, setCurrentLanguage] = useState(languages[0]);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    }
    
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }
    

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleLanguageMenuOpen = (event) => {
        setLanguageAnchorEl(event.currentTarget);
      };
    
      const handleLanguageMenuClose = () => {
        setLanguageAnchorEl(null);
      };

      const handleLanguageChange = (language) => {
        setCurrentLanguage(language);
        handleLanguageMenuClose(); 
      };

    // useEffect(() => {
    //     if (drawerRef.current) {
    //         const drw = drawerRef.current;
	// 		drw.select();
    //     }
    // }, [drawerOpen]);

    useEffect(() => {
      if (!drawerRef?.current) return
      const input = drawerRef.current;
      input.select();
    }, []);

    return (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>

            {/* <Box display="flex" alignItems="center">
              <img src={logo} alt="Logo" className={classes.logo} />
              <Typography variant="h6">{ appName }</Typography>
            </Box> */}

            <Box display="flex" alignItems="center">
                <a href="/" className={classes.logoLink}>
                    <img src={logo} alt="Logo" className={classes.logo} />
                    <Typography variant="h6">{appName}</Typography>
                </a>
            </Box>
            
            {isMobile ? (
              <>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={handleDrawerToggle}
                  classes={{ paper: classes.drawerPaper }}
                >
                  <List className={classes.drawerList}>

                    {categories.map((category, index) => (
                      <Link key={index} to={category.path} className={classes.link}>
                        <ListItem button key={index}>
                          <ListItemText primary={category.label} />
                        </ListItem>
                      </Link>
                      // <ListItem
                      //   button
                      //   key={index}
                      //   onClick={() => {
                      //     handleDrawerToggle();
                      //     if (category.path === '') {
                      //       onMenuClick(sections[category.label]);
                      //     }
                      //   }}
                      // >
                      //   <ListItemText primary={category.label} />
                      // </ListItem>
                    ))}


                  {/* {categories.map((category, index) => (
                    <Link key={index} to={category.path} className={classes.link}>
                      <Tab label={category.label} className={classes.tab} />
                    </Link>
                  ))} */}


                    <Divider />

                    {/* <ListItem>
                      <ListItemText primary="Контакти" />
                    </ListItem> */}

                    {phoneNumbers.map((number, index) => (
                      <ListItem key={index}>
                        <PhoneIcon />
                        <ListItemText primary={number} />
                      </ListItem>
                    ))}

                    <Divider />
                    <br />
                    <div className={classes.languageContainer}>
                      { languages.map((language, index) => (
                        <button
                          key={index}
                          className={classes.languageButton}
                          style={{opacity: currentLanguage.code === language.code ? 1 : .5}}
                          onClick={() => handleLanguageChange(language)}
                        >
                          <img 
                            src={language.code === 'UA' ? uaFlag : language.code === 'ENG' ? engFlag : language.code === 'PL' ? plFlag : uaFlag} 
                            alt={language.code} 
                            className={classes.flagIcon} 
                          />
                          <span className={classes.languageCode}>{language.code}</span>
                        </button>
                      ))}
                    </div>

                  </List>
                </Drawer>
              </>
            ) : (
              <>
                <Button
                  startIcon={<PhoneIcon />}
                  endIcon={anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  onClick={handleMenuOpen}
                  color="inherit"
                >
                  {phoneNumbers[0]}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {phoneNumbers.map((number, index) => (
                    <MenuItem key={index} onClick={handleMenuClose}>
                      {number}
                    </MenuItem>
                  ))}
                </Menu>

                <Button
                  startIcon={
                    <img 
                        src={currentLanguage.code === 'UA' ? uaFlag : currentLanguage.code === 'ENG' ? engFlag : currentLanguage.code === 'PL' ? plFlag : uaFlag} 
                        alt={currentLanguage.code} 
                        className={classes.flagIcon} 
                    />
                  }
                  endIcon={languageAnchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  onClick={handleLanguageMenuOpen}
                  color="inherit"
                >
                  {currentLanguage.code}
                </Button>
                <Menu
                  anchorEl={languageAnchorEl}
                  keepMounted
                  open={Boolean(languageAnchorEl)}
                  onClose={handleLanguageMenuClose}
                >
                  {languages.map((language, index) => (
                    <MenuItem key={index} onClick={() => handleLanguageChange(language)}>
                      <ListItemIcon>
                        <img src={language.flag} alt={language.code} className={classes.flagIcon} />
                      </ListItemIcon>
                      {language.label}
                    </MenuItem>
                  ))}
                </Menu>

                {/* <Tabs value={false} className={classes.tabs}>
                  {categories.map((category, index) => (
                    <Tab key={index} label={category} className={classes.tab} />
                  ))}
                </Tabs> */}

                <Tabs value={false} className={classes.tabs}>
                  {categories.map((category, index) => (
                          <Link key={index} to={category.path} className={classes.link}>
                            <Tab label={category.label} className={classes.tab} />
                          </Link>
                  ))}
                </Tabs>

                {/* <Tabs value={false} className={classes.tabs}>
                  {categories.map((category, index) => (
                  
                      category.path === '' ? 

                          <Tab
                            key={index}
                            label={category.label}
                            className={classes.tab}
                            onClick={() => {
                                onMenuClick(sections[category.label]);
                            }}
                          />
                        : 
                          <Link key={index} to={category.path} className={classes.link}>
                            <Tab label={category.label} className={classes.tab} />
                          </Link>
                    
              
                  ))}
                </Tabs> */}

              </>
            )}
          </Toolbar>
        </AppBar>
    )
}

export default withStyles(headerStyles)(CustomHeader)