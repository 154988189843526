import React, { useEffect, useState, useRef } from 'react';

import {
	Box,
	makeStyles,
	useTheme,
    Typography,
    TextField,
    Button,
    Grid,
    IconButton,
} from '@material-ui/core';

import AOS from 'aos';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';

import CustomHeader from '../../components/elements/CustomHeader';
import CustomHeaderLevel2 from '../../components/elements/CustomHeaderLevel2';
import NavPanel from '../../components/elements/NavPanel';
import TelegramFab from '../../components/elements/TelegramFab';
import ViberFab from '../../components/elements/ViberFab';

import ServicesSection from '../HomeScreen/components/ServicesSection';

import CustomFooter from '../../components/elements/CustomFooter';

import { withStyles } from '@material-ui/core/styles';
import { homeStyles } from '../../themes/theme-white';

import { categories } from '../../config/myConstants';
import config from '../../config/config';

import mapImage from '../../assets/map/map.jpg';

function ContactUsScreen({classes}) {

	const theme = useTheme();
	const localStyles = useStyles();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

	return (

		<div className={classes.root}>

			<div>
				
				{ config.elements.showViberBotButton && <ViberFab /> }

				{ config.elements.showTelegramBotButton && <TelegramFab /> }

			</div>

            <CustomHeaderLevel2 activeCategory={categories[6].label} />

			<NavPanel navRoute={[categories[6].label]} />
            
			{/* <Box 
				className={classes.tempBlock}
				style={{
					height: '1000px',
					backgroundColor: 'white'
				}}
			>
			</Box> */}

			<Box className={localStyles.root}>

				<Box className={localStyles.mapContainer} data-aos="fade-up" >
					<img src={mapImage} alt="Map" className={localStyles.mapImage} />
				</Box>
				
				<Grid container spacing={4} className={localStyles.content}>
					<Grid item xs={12} md={4} data-aos="fade-up" data-aos-delay={200}>
						<Box className={localStyles.contactInfo}>
							<Box className={localStyles.contactItem}>
								<Box className={localStyles.iconBox}>
									<LocationOnOutlinedIcon className={localStyles.icon} />
								</Box>
								<Box className={localStyles.textBox}>
									<Typography variant="h6" className={localStyles.textBoxTitle}>Location:</Typography>
									<Typography variant="body1" className={localStyles.textBoxDescription}>Україна, Київ, вул. Кільцева дорога, 14</Typography>
								</Box>
							</Box>
							<Box className={localStyles.contactItem}>
								<Box className={localStyles.iconBox}>
									<EmailOutlinedIcon className={localStyles.icon} />
								</Box>
								<Box className={localStyles.textBox}>
									<Typography variant="h6" className={localStyles.textBoxTitle}>Email:</Typography>
									<Typography variant="body1" className={localStyles.textBoxDescription}>logis.logistics@gmail.com</Typography>
								</Box>
							</Box>
							<Box className={localStyles.contactItem}>
								<Box className={localStyles.iconBox}>
									<PhoneAndroidOutlinedIcon className={localStyles.icon} />
								</Box>
								<Box className={localStyles.textBox}>
									<Typography variant="h6" className={localStyles.textBoxTitle}>Call:</Typography>
									<Typography variant="body1" className={localStyles.textBoxDescription}>+380 (67) 876-54-32</Typography>
								</Box>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={12} md={8} className={localStyles.formContainer} data-aos="fade-up" data-aos-delay={300}>
						<form className={localStyles.form}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<TextField label="Ім'я" fullWidth variant="outlined" required />
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField label="Електронна пошта" fullWidth variant="outlined" required />
								</Grid>
								<Grid item xs={12}>
									<TextField label="Тема" fullWidth variant="outlined" required />
								</Grid>
								<Grid item xs={12}>
									<TextField
										label="Повідомлення"
										fullWidth
										variant="outlined"
										multiline
										rows={4}
										required
									/>
								</Grid>
							</Grid>
							<Box mt={2} style={{textAlign: 'center'}}>
								<Button type="submit" variant="contained" color="primary">
									Відправити
								</Button>
							</Box>
						</form>
					</Grid>
				</Grid>
			</Box>

			<CustomFooter />
			
        </div>
    
	)

}

const useStyles = makeStyles((theme) => ({
    root: {
        // padding: theme.spacing(4),
		padding: `${theme.spacing(10)}px ${theme.spacing(20)}px`,
		[theme.breakpoints.down('1500')]: {
			padding: `${theme.spacing(10)}px ${theme.spacing(10)}px`,
		},
		[theme.breakpoints.down('1200')]: {
			padding: `${theme.spacing(10)}px ${theme.spacing(5)}px`,
		},
		[theme.breakpoints.down('1000')]: {
			padding: `${theme.spacing(8)}px ${theme.spacing(5)}px`,
		},
		[theme.breakpoints.down('800')]: {
			padding: `${theme.spacing(5)}px ${theme.spacing(10)}px`,
		},
		[theme.breakpoints.down('600')]: {
			padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`,
		},
    },
    mapContainer: {
        width: '100%',
        marginBottom: theme.spacing(4),
		// border: '1px solid blue'
    },
    mapImage: {
        width: '100%',
        height: '400px',
		objectFit: 'cover', 
		border: '0.5px solid #BED8F4'
    },
    content: {
        // maxWidth: '1200px',
        margin: '0 auto',
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(5),
		// justifyContent: 'space-between'
    },
    contactItem: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
	iconBox: {
		backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: theme.spacing(0.2),
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '45px',
        minHeight: '45px',
	},
    icon: {
		// backgroundColor: 'blue',
        // color: '#fff',
		fontSize: '24px',
    },
	textBox: {
        display: 'flex',
        flexDirection: 'column',
    },
	textBoxTitle: {
        color: '#0E1D34',
		fontSize: '20px'
    },
	textBoxDescription: {
        color: '#3E4A5D',
		fontSize: '14px'
    },
    formContainer: {
        paddingLeft: `${theme.spacing(15)}px`,
		[theme.breakpoints.down('800')]: {
			paddingLeft: `${theme.spacing(0)}px`
		},
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));
export default withStyles(homeStyles)(ContactUsScreen)