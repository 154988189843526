import image1 from '../assets/service/storage-service.jpg'; 
import image2 from '../assets/service/logistics-service.jpg'; 
import image3 from '../assets/service/cargo-service.jpg'; 
import image4 from '../assets/service/trucking-service.jpg'; 
import image5 from '../assets/service/packaging-service.jpg'; 
import image6 from '../assets/service/warehousing-service.jpg'; 

export const categories = [
    { label: 'Головна', path: '/' },
    { label: 'Про нас', path: '/aboutus' },
    { label: 'Напрямки', path: '/directions' },
    { label: 'Ціни', path: '/price' },
    { label: 'Переваги', path: '/advantages' },
    { label: 'Поширені питання', path: '/faq' },
    { label: 'Контакти', path: '/contactus' },
];

export const services = [
    { title: 'Автоперевезення', shortDescription: 'Швидкі та безпечні перевезення вантажів по Україні та ЄС. З нами вчасно, вигідно та надійно!', link: '/services/trucking-details', image: image4 },
    { title: 'Зберігання', shortDescription: 'Надійне зберігання ваших вантажів з максимальним рівнем безпеки та доступу!', link: '/services/storage-details', image: image1 },
    { title: 'Логістика', shortDescription: 'Комплексні логістичні рішення для ефективного управління вашими поставками!', link: '/services/logistics-details', image: image2 },
    { title: 'Пакування', shortDescription: 'Професійне пакування вантажів для збереження якості під час транспортування!', link: '/services/packaging-details', image: image5 },
    { title: 'Складування', shortDescription: 'Безпечне складування товарів з контролем клімату та умов зберігання!', link: '/services/warehousing-details', image: image6 },
    { title: 'Страхування', shortDescription: 'Надійне страхування вантажів для максимального захисту від можливих ризиків!', link: '/services/insurance-details', image: image3 },
];
