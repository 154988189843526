import React, { useEffect, useState, useRef } from 'react';

import {
    useTheme,
    Fab
} from '@material-ui/core';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { telegramFabStyles } from '../../themes/theme-white';

import TelegramIcon from '@material-ui/icons/Telegram';

import config from '../../config/config';

function TelegramFab({classes}) {

  const theme = useTheme();

  return (
    <Fab
      href="https://t.me/JustDontGiveUp" 
      target="_blank"
      rel="noopener noreferrer"
      className={classes.telegramFab}
    >
      <TelegramIcon className={classes.telegramIcon} />
    </Fab>
  )
}

export default withStyles(telegramFabStyles)(TelegramFab)