import React, { useEffect, useState, useRef } from 'react';

import {
	AppBar,
	Toolbar,
	Typography,
	Tabs,
	Tab,
	Box,
	makeStyles,
	useTheme,
	useMediaQuery,
	IconButton,
	Drawer,
	List,
	ListItem,
	ListItemText,
	Fab,
} from '@material-ui/core';

import CustomHeader from '../../components/elements/CustomHeader';
import SliderSection from './components/SliderSection';
import AboutUsSection from './components/AboutUsSection';
import ServicesSection from './components/ServicesSection';
import CallToActionSection from './components/CallToActionSection';
import AdvantagesSection from './components/AdvantagesSection';
import FaqSection from './components/FaqSection';

import CustomFooter from '../../components/elements/CustomFooter';
import TelegramFab from '../../components/elements/TelegramFab';
import ViberFab from '../../components/elements/ViberFab';

import { withStyles } from '@material-ui/core/styles';

import { homeStyles } from '../../themes/theme-white';

import config from '../../config/config';

function HomeScreen({classes}) {

	const theme = useTheme();

	const aboutUsRef = useRef(null);
	const servicesRef = useRef(null);
	// const callToActionRef = useRef(null);
	const advantagesRef = useRef(null);
	const faqRef = useRef(null);

	const handleScroll = (ref) => {
		if (ref.current) {
		  ref.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const sections = {
		'Про нас': aboutUsRef,
		'Напрямки': servicesRef,
		// 'Ціни': callToActionRef,
		'Переваги': advantagesRef,
		'Часті запитання': faqRef,
	  };

	return (

		<div className={classes.root}>

			<div>
				
				{ config.elements.showViberBotButton && <ViberFab /> }

				{ config.elements.showTelegramBotButton && <TelegramFab /> }

			</div>


            <CustomHeader onMenuClick={handleScroll} sections={sections} />
            
            {/* <Toolbar />  */}

			<SliderSection />

            {/* <Toolbar />  */}

			{/* <Box ref={aboutUsRef}>
				<AboutUsSection />
			</Box>

			<Box ref={servicesRef}>
				<ServicesSection />
			</Box>

			<Box>
				<CallToActionSection />
			</Box>

			<Box ref={advantagesRef}>
				<AdvantagesSection />
			</Box>

			<Box ref={faqRef}>
				<FaqSection />
			</Box> */}

			<AboutUsSection isMain={true} />

			<ServicesSection isMain={true} />

			<CallToActionSection isMain={true} />

			<AdvantagesSection isMain={true} />

			<FaqSection isMain={true} />

			<CustomFooter isMain={true} />

        </div>
    
	)

}

const style = {
	container: {
		width: '100%',
		padding: '50px 100px'
		// border: '1px solid blue'
	}
}

export default withStyles(homeStyles)(HomeScreen)