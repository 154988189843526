import React, { useEffect, useState, useRef } from 'react';

import {
	AppBar,
	Toolbar,
	Typography,
	Tabs,
	Tab,
	Box,
	makeStyles,
	useTheme,
	useMediaQuery,
	IconButton,
	Drawer,
	List,
	ListItem,
	ListItemText,
} from '@material-ui/core';


import CustomHeader from '../../components/elements/CustomHeader';
import CustomHeaderLevel2 from '../../components/elements/CustomHeaderLevel2';
import NavPanel from '../../components/elements/NavPanel';
import TelegramFab from '../../components/elements/TelegramFab';
import ViberFab from '../../components/elements/ViberFab';

import ServicesSection from '../HomeScreen/components/ServicesSection';
import FaqSection from '../HomeScreen/components/FaqSection';

import CustomFooter from '../../components/elements/CustomFooter';

import { withStyles } from '@material-ui/core/styles';
import { homeStyles } from '../../themes/theme-white';

import { categories } from '../../config/myConstants';

import config from '../../config/config';

function FaqScreen({classes}) {

	const theme = useTheme();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (

		<div className={classes.root}>

			<div>
				
				{ config.elements.showViberBotButton && <ViberFab /> }

				{ config.elements.showTelegramBotButton && <TelegramFab /> }

			</div>

            <CustomHeaderLevel2 activeCategory={ categories[5].label } />

			<NavPanel 
				navRoute={[ categories[5].label ]} 
				// navRoute={['Напрямки', 'Пакування']} 
			/>

			<FaqSection isMain={false} />
            
			{/* <Box 
				className={classes.tempBlock}
				style={{
					height: '2000px',
					backgroundColor: 'white'
				}}
			>
			</Box> */}

			<CustomFooter />
			
        </div>
    
	)

}

const style = {
	container: {
		width: '100%',
		padding: '50px 100px'
		// border: '1px solid blue'
	}
}

export default withStyles(homeStyles)(FaqScreen)