import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { GridLoader } from 'react-spinners';

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
}));

function CustomLoader() {
  const classes = useStyles();
  return (
    <Box className={classes.loaderContainer}>
      <GridLoader size={20} color={"#123abc"} loading={true} />
    </Box>
  );
}

export default CustomLoader;



