import React, { useEffect, useState, useRef } from 'react';

import {
	AppBar,
	Toolbar,
	Typography,
	Tabs,
	Tab,
	Box,
	Grid,
	Card,
	CardContent,
	CardMedia,
	makeStyles,
	useTheme,
	useMediaQuery,
	IconButton,
	Drawer,
	List,
	ListItem,
	ListItemText,
} from '@material-ui/core';

import AOS from 'aos';

import CustomHeader from '../../components/elements/CustomHeader';
import CustomHeaderLevel2 from '../../components/elements/CustomHeaderLevel2';
import NavPanel from '../../components/elements/NavPanel';
import TelegramFab from '../../components/elements/TelegramFab';
import ViberFab from '../../components/elements/ViberFab';

import ServicesSection from '../HomeScreen/components/ServicesSection';

import CustomFooter from '../../components/elements/CustomFooter';

import { withStyles } from '@material-ui/core/styles';
import { homeStyles } from '../../themes/theme-white';

import { categories } from '../../config/myConstants';
import config from '../../config/config';

import serviceImg1 from '../../assets/price/service1.jpg';
import serviceImg2 from '../../assets/price/service2.jpg';

function PriceScreen({classes}) {

	const theme = useTheme();
	const localStyles = useStyles();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

	const priceData = [
		{ category: 'Автодоставка', price: 'від 20 грн/км', image: serviceImg1 },
		{ category: 'Авіадоставка', price: 'від 100 грн/кг', image: serviceImg2 },
		{ category: 'Доставка морем', price: 'від 2000 грн/контейнер', image: serviceImg1 },
		{ category: 'Складування', price: 'від 50 грн/м²', image: serviceImg2 },
		{ category: 'Пакування', price: 'від 10 грн/од.', image: serviceImg1 },
		{ category: 'Страхування', price: 'від 1% вартості вантажу', image: serviceImg2 },
	];

	return (

		<div className={classes.root}>

			<div>
				
				{ config.elements.showViberBotButton && <ViberFab /> }

				{ config.elements.showTelegramBotButton && <TelegramFab /> }

			</div>

            <CustomHeaderLevel2 activeCategory={categories[3].label} />

			<NavPanel 
				navRoute={[categories[3].label]} 
				// navRoute={['Напрямки', 'Пакування']} 
			/>

			{/* <ServicesSection /> */}
            
			{/* <Box 
				className={classes.tempBlock}
				style={{
					height: '1000px',
					backgroundColor: 'white'
				}}
			>
			</Box> */}

			<Box className={localStyles.root}>
				<Box className={localStyles.titleContainer} data-aos="fade-up">
					<Typography variant="h4" className={localStyles.title}>ЦІНИ</Typography>
					<Typography variant="h1" className={localStyles.titleBackground}>НАШІ ЦІНИ</Typography>
				</Box>
				{/* <Typography variant="h4" gutterBottom align="center">
					Наші Послуги та Ціни
				</Typography> */}
				<Grid container spacing={4} justifyContent="center" style={{marginTop: '40px'}}>
					{priceData.map((item, index) => (
						<Grid 
							item xs={12} sm={6} md={4} key={index} 
							data-aos="fade-up" 
							data-aos-delay={index * 150}
						>
							<Card className={localStyles.card}>
								<CardMedia
									className={localStyles.media}
									image={item.image}
									title={item.category}
								/>
								<CardContent>
									<Typography className={localStyles.title} color="textSecondary" gutterBottom>
										{item.category}
									</Typography>
									<Typography variant="h5" component="h2">
										{item.price}
									</Typography>
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>
			</Box>

			<CustomFooter />
			
        </div>
    
	)

}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
		paddingTop: `${theme.spacing(10)}px`,
        backgroundColor: theme.palette.background.paper,
    },
    card: {
        margin: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
    },
    media: {
        height: 240,
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    pos: {
        marginBottom: 12,
        color: theme.palette.text.secondary,
    },
	title: {
		color: '#19335C',
		paddingBottom: theme.spacing(2),
		position: 'relative',
		textTransform: 'uppercase',  
		fontWeight: theme.typography.fontWeightBold,  
		fontSize: theme.typography.h4.fontSize,
		zIndex: 1,
		textAlign: 'center',
		  '&::before': {
			  content: '""',
			  position: 'absolute',
			  display: 'block',
			  width: '120px',
			  height: '1px',
			  background: '#ddd',
			  bottom: '1px',
			  left: 'calc(50% - 60px)',
		  },
		  '&::after': {
			  content: '""',
			  position: 'absolute',
			  display: 'block',
			  width: '40px',
			  height: '3px',
			  background: '#47b2e4',
			  bottom: '0',
			  left: 'calc(50% - 20px)',
		  },
	},
	titleBackground: {
		  position: 'absolute',
		  top: '35%',
		  left: '50%',
		  transform: 'translate(-50%, -50%)',
		  color: '#000',
		  opacity: .05,
		  zIndex: 0,
		  letterSpacing: 6,
		  whiteSpace: 'nowrap',
		  fontWeight: theme.typography.fontWeightBold, 
		  fontSize: theme.typography.h2.fontSize
	},
}));

export default withStyles(homeStyles)(PriceScreen)