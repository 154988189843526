import React, { useEffect, useState, useRef } from 'react';

import {
  Modal,
  Backdrop,
  Fade,
  Typography,
  TextField,
  Grid,
  Button,
  MenuItem,
  IconButton,
  Select,
  InputLabel,
  FormControl,
  makeStyles,
  SvgIcon,
  useTheme,
} from '@material-ui/core';

import { Link } from 'react-router-dom';

import ContactSupportIcon from '@material-ui/icons/ContactSupport';

import { withStyles } from '@material-ui/core/styles';
import { consultationModalStyles } from '../../themes/theme-white';

import config from '../../config/config';

const contactTypes = [
  { value: 'email', label: 'Email' },
  { value: 'phone', label: 'Phone' },
];

function ConsultationModal({classes, modalOpen, handleCloseModal, handleFormSubmit}) {

  const theme = useTheme();

  const [contactType, setContactType] = useState('email');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [question, setQuestion] = useState('');
  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePhone = (phone) => {
        const re = /^(?:\+?38)?(?:\(?0\d{2}\)?[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2})$/;
        return re.test(String(phone));
    };

    const handleContactTypeChange = (event) => {
        setContactType(event.target.value);
        setEmail('');
        setPhone('');
        setErrors({});
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        let validationErrors = {};

        if (!name) {
            validationErrors.name = 'Ім\'я є обов\'язковим';
        }

        if (contactType === 'email') {
            if (!email) {
                validationErrors.email = 'Email є обов\'язковим';
            } else if (!validateEmail(email)) {
                validationErrors.email = 'Некоректний формат email';
            }
        } else {
            if (!phone) {
                validationErrors.phone = 'Телефон є обов\'язковим';
            } else if (!validatePhone(phone)) {
                validationErrors.phone = 'Некоректний формат телефону';
            }
        }

        if (!question) {
            validationErrors.question = 'Будь ласка, вкажіть ваше питання';
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            handleFormSubmit({ name, contactType, email, phone, question });
            setName('');
            setQuestion('');
            setEmail('');
            setPhone('');
            setErrors({});
            handleCloseModal();
        }
    };

  return (
    // <Modal
    //     aria-labelledby="transition-modal-title"
    //     aria-describedby="transition-modal-description"
    //     className={classes.modal}
    //     open={modalOpen}
    //     onClose={handleCloseModal}
    //     closeAfterTransition
    //     BackdropComponent={Backdrop}
    //     BackdropProps={{
    //         timeout: 500,
    //     }}
    // >
    //     <Fade in={modalOpen}>
    //         <div className={classes.paper}>
    //             <SvgIcon className={classes.formIcon}>
    //                 <ContactSupportIcon style={{ fontSize: 50, color: '#19335C' }} />
    //             </SvgIcon>
    //             <Typography variant="h6" id="transition-modal-title" gutterBottom style={{color: '#19335C' }}>
    //                 Для отримання консультації сформулюйте ваші питання і залиште контактну інформацію
    //             </Typography>
    //             <form onSubmit={handleFormSubmit}>
    //                 <TextField
    //                     label="Ім'я"
    //                     fullWidth
    //                     margin="normal"
    //                     variant="outlined"
    //                     value={name}
    //                     onChange={(e) => setName(e.target.value)}
    //                     required
    //                     className={classes.textField}
    //                 />
    //                 <FormControl variant="outlined" fullWidth className={classes.textField} required>
    //                     <InputLabel id="contact-type-label">Тип контакту</InputLabel>
    //                     <Select
    //                         labelId="contact-type-label"
    //                         value={contactType}
    //                         onChange={handleContactTypeChange}
    //                         label="Тип контакту"
    //                     >
    //                         <MenuItem value="email">Email</MenuItem>
    //                         <MenuItem value="phone">Phone</MenuItem>
    //                     </Select>
    //                 </FormControl>
    //                 {contactType === 'email' ? (
    //                     <TextField
    //                         label="Email"
    //                         type="email"
    //                         fullWidth
    //                         margin="normal"
    //                         variant="outlined"
    //                         value={contactValue}
    //                         onChange={(e) => setContactValue(e.target.value)}
    //                         required
    //                         className={classes.textField}
    //                     />
    //                 ) : (
    //                     <TextField
    //                         label="Номер телефону"
    //                         type="tel"
    //                         fullWidth
    //                         margin="normal"
    //                         variant="outlined"
    //                         value={contactValue}
    //                         onChange={(e) => setContactValue(e.target.value)}
    //                         required
    //                         className={classes.textField}
    //                     />
    //                 )}
    //                 <TextField
    //                     label="Ваше питання"
    //                     fullWidth
    //                     margin="normal"
    //                     variant="outlined"
    //                     value={question}
    //                     onChange={(e) => setQuestion(e.target.value)}
    //                     required
    //                     className={classes.textField}
    //                     multiline
    //                     rows={4}
    //                 />
    //                 <Grid container justifyContent="flex-end" className={classes.buttonContainer}>
    //                     <Button type="submit" variant="contained" color="primary">
    //                         Відправити
    //                     </Button>
    //                 </Grid>
    //             </form>
    //         </div>
    //     </Fade>
    // </Modal>
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
    >
        <Fade in={modalOpen}>
            <div className={classes.paper}>
                <SvgIcon className={classes.formIcon}>
                    <ContactSupportIcon style={{ fontSize: 50, color: '#19335C' }} />
                 </SvgIcon>
                 <Typography variant="h6" id="transition-modal-title" gutterBottom style={{color: '#19335C' }}>
                    Для отримання консультації сформулюйте ваші питання і залиште контактну інформацію
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Ім'я"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={!!errors.name}
                        helperText={errors.name}
                        required
                    />
                    <TextField
                        select
                        label="Тип контакту"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={contactType}
                        onChange={handleContactTypeChange}
                        required
                        className={classes.textField}
                    >
                        {contactTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    {contactType === 'email' ? (
                        <TextField
                            label="Email"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={!!errors.email}
                            helperText={errors.email}
                            required
                        />
                    ) : (
                        <TextField
                            label="Номер телефону"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            error={!!errors.phone}
                            helperText={errors.phone}
                            required
                        />
                    )}
                    <TextField
                        label="Ваше питання"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        error={!!errors.question}
                        helperText={errors.question}
                        multiline
                        rows={4}
                        required
                    />
                    <Grid container justify="flex-end">
                        <Button type="submit" variant="contained" color="primary">
                            Відправити
                        </Button>
                    </Grid>
                </form>
            </div>
        </Fade>
    </Modal>
  )
}

export default withStyles(consultationModalStyles)(ConsultationModal)