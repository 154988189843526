import React, { useEffect, useState, useRef } from 'react';

import { 
    Box, 
    Grid, 
    Typography, 
    useTheme, 
    Link 
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { serviceSectionStyles } from '../../../themes/theme-white';

import AOS from 'aos';

// import image1 from '../../../assets/service/storage-service.jpg'; 
// import image2 from '../../../assets/service/logistics-service.jpg'; 
// import image3 from '../../../assets/service/cargo-service.jpg'; 
// import image4 from '../../../assets/service/trucking-service.jpg'; 
// import image5 from '../../../assets/service/packaging-service.jpg'; 
// import image6 from '../../../assets/service/warehousing-service.jpg'; 

import config from '../../../config/config';

import { services } from '../../../config/myConstants';


// const services = [
//     { title: 'Автоперевезення', shortDescription: 'Швидкі та безпечні перевезення вантажів по Україні та ЄС. З нами вчасно, вигідно та надійно!', link: '/service-details', image: image4 },
//     { title: 'Зберігання', shortDescription: 'Надійне зберігання ваших вантажів з максимальним рівнем безпеки та доступу!', link: '/service-details', image: image1 },
//     { title: 'Логістика', shortDescription: 'Комплексні логістичні рішення для ефективного управління вашими поставками!', link: '/service-details', image: image2 },
//     { title: 'Пакування', shortDescription: 'Професійне пакування вантажів для збереження якості під час транспортування!', link: '/service-details', image: image5 },
//     { title: 'Складування', shortDescription: 'Безпечне складування товарів з контролем клімату та умов зберігання!', link: '/service-details', image: image6 },
//     { title: 'Страхування', shortDescription: 'Надійне страхування вантажів для максимального захисту від можливих ризиків!', link: '/service-details', image: image3 },
// ];

// const services = [
//     { title: 'Автоперевезення', shortDescription: 'Швидкі та безпечні перевезення вантажів по Україні та ЄС. З нами вчасно, вигідно та надійно!', link: '/services/trucking-details', image: image4 },
//     { title: 'Зберігання', shortDescription: 'Надійне зберігання ваших вантажів з максимальним рівнем безпеки та доступу!', link: '/services/storage-details', image: image1 },
//     { title: 'Логістика', shortDescription: 'Комплексні логістичні рішення для ефективного управління вашими поставками!', link: '/services/logistics-details', image: image2 },
//     { title: 'Пакування', shortDescription: 'Професійне пакування вантажів для збереження якості під час транспортування!', link: '/services/packaging-details', image: image5 },
//     { title: 'Складування', shortDescription: 'Безпечне складування товарів з контролем клімату та умов зберігання!', link: '/services/warehousing-details', image: image6 },
//     { title: 'Страхування', shortDescription: 'Надійне страхування вантажів для максимального захисту від можливих ризиків!', link: '/services/insurance-details', image: image3 },
// ];

function ServicesSection({classes}) {

    const theme = useTheme();

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <Box className={classes.servicesSection}>
            <Box className={classes.titleContainer} data-aos="fade-up">
                <Typography variant="h4" className={classes.title}>ПОСЛУГИ</Typography>
                <Typography variant="h1" className={classes.titleBackground}>ПОСЛУГИ</Typography>
            </Box>
            <Grid container spacing={2} style={{marginTop: '40px'}}>
                {services.map((service, index) => (
                    <Grid 
                        item xs={12} 
                        sm={6} 
                        md={4} 
                        key={index}
                        data-aos="fade-up" 
                        data-aos-delay={index * 200}
                    >
                        
                        <Link 
                            href={service.link} 
                            className={classes.serviceLink}
                        >
                            <Box className={classes.serviceBox}>

                                <Box className={classes.imageWrapper}>
                                    <img src={service.image} alt={service.title} className={classes.serviceImage} />
                                </Box>
                                
                                <Box style={{padding: '10px 25px 20px 25px'}}>
                                    <Typography variant="h6" className={classes.serviceTitle}>{service.title}</Typography>
                                    <Typography style={{marginTop: '5px'}}>{service.shortDescription}</Typography>
                                </Box>
                            </Box>
                        </Link>

                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default withStyles(serviceSectionStyles)(ServicesSection)

