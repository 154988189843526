import React, { useEffect, useState, useRef } from 'react';

import { 
    Box, 
    Grid, 
    Typography, 
    useTheme, 
    makeStyles,
    Link 
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import AOS from 'aos';

import { advantagesSectionStyles } from '../../../themes/theme-white';

import config from '../../../config/config';

import image1 from '../../../assets/advantages/modern_car_fleet.jpg'; 
import image2 from '../../../assets/advantages/best_team.jpg'; 
import image3 from '../../../assets/advantages/induvidual_approuch.jpg'; 
import image4 from '../../../assets/advantages/cargo_tracking.jpg'; 
import image5 from '../../../assets/advantages/goals_target.jpg'; 

const advantages = [
    { 
        title: 'Сучасний автопарк', 
        shortDescription: 'Ми використовуємо новітні транспортні засоби, які відповідають всім міжнародним стандартам', 
        description: `Наша компанія пишається своїм сучасним автопарком, який складається з найновіших моделей вантажівок провідних світових виробників. Всі транспортні засоби обладнані передовими технологіями, що забезпечують максимальну безпеку та ефективність перевезень. Завдяки регулярному технічному обслуговуванню та модернізації ми гарантуємо надійність і своєчасність доставки вашого вантажу до будь-якої точки світу`,
        link: '/advantage/advantage1', 
        image: image1 
    },
    { 
        title: 'Професійна команда', 
        shortDescription: 'Наші водії та логісти мають багаторічний досвід у сфері міжнародних перевезень', 
        description: `Наша компанія пишається своєю професійною командою, яка складається з досвідчених водіїв, логістів та менеджерів. Кожен член нашої команди пройшов ретельний відбір та навчання, щоб забезпечити найвищий рівень сервісу для наших клієнтів. Ми працюємо злагоджено та оперативно, щоб ваші вантажі були доставлені вчасно та безпечно`,
        link: '/advantage/advantage2', 
        image: image2 
    },
    { 
        title: 'Індивідуальний підхід', 
        shortDescription: 'Ми адаптуємо наші послуги під потреби кожного клієнта', 
        description: `Ми розуміємо, що кожен клієнт має унікальні потреби та вимоги, тому пропонуємо індивідуальний підхід до кожного замовлення. Наша команда уважно вислухає ваші побажання та розробить оптимальне рішення для перевезення вашого вантажу. Ми прагнемо забезпечити максимальний комфорт та зручність для наших клієнтів, пропонуючи гнучкі та персоналізовані послуги`,
        link: '/advantage/advantage3', 
        image: image3 
    },
    { 
        title: 'Відстеження вантажу', 
        shortDescription: 'Онлайн система відстеження дозволяє контролювати місцезнаходження вашого вантажу в будь-який момент', 
        description: `Ми пропонуємо нашим клієнтам зручну послугу відстеження вантажу в режимі реального часу. Завдяки сучасним системам GPS-моніторингу ви можете у будь-який момент дізнатися місцезнаходження вашого вантажу та отримати актуальну інформацію про його статус. Ми прагнемо забезпечити повну прозорість та впевненість у процесі доставки, щоб ви завжди були в курсі кожного етапу перевезення`,
        link: '/advantage/advantage4', 
        image: image4 
    },
    { 
        title: 'Економічна ефективність', 
        shortDescription: 'Пропонуємо конкурентні тарифи та оптимальні маршрути для зниження витрат', 
        description: `Ми прагнемо забезпечити високу економічну ефективність наших послуг, пропонуючи конкурентоспроможні тарифи та оптимальні рішення для перевезення вашого вантажу. Наші фахівці ретельно планують маршрути та використовують сучасні технології для мінімізації витрат і часу доставки. Ми гарантуємо, що співпраця з нами буде вигідною та економічно обґрунтованою для вашого бізнесу`,
        link: '/advantage/advantage5', 
        image: image5 
    },
];

function AdvantagesSection({classes, isMain = true}) {

    const theme = useTheme();
    const localStyles = useStyles();

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <Box className={classes.advantagesSection}>
            {
                !isMain && 
                    <Box className={localStyles.titleContainer} data-aos="fade-up">
                        <Typography variant="h4" className={localStyles.mainTitle}>ПЕРЕВАГИ</Typography>
                        <Typography variant="h1" className={localStyles.titleBackground}>ПЕРЕВАГИ</Typography>
                    </Box>
            }
            {advantages.map((advantage, index) => (
                <Grid 
                    container 
                    spacing={2} 
                    className={classes.advantage} 
                    style={{marginTop: isMain ? '30px' : '50px'}}
                    key={index} 
                    data-aos="fade-up" 
                    data-aos-delay={(index+1) * 100}
                >
                    {index % 2 === 0 ? (
                        <>
                            <Grid item xs={12} md={6} className={classes.imageContainer} style={{textAlign: 'left'}}>
                                <img src={advantage.image} alt={advantage.title} className={classes.image} />
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.textContainer}>
                                <Typography variant="h4" className={classes.title}>{advantage.title}</Typography>
                                <Typography className={classes.shortDescription}>{advantage.shortDescription}</Typography>
                                <Typography className={classes.description}>{advantage.description}</Typography>
                                {/* <Link href={advantage.link} className={classes.link}>Читати більше →</Link> */}
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12} md={6} className={classes.textContainer}>
                                <Typography variant="h4" className={classes.title}>{advantage.title}</Typography>
                                <Typography className={classes.shortDescription}>{advantage.shortDescription}</Typography>
                                <Typography className={classes.description}>{advantage.description}</Typography>
                                {/* <Link href={advantage.link} className={classes.link}>Читати більше →</Link> */}
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.imageContainer} style={{textAlign: 'end'}}>
                                <img src={advantage.image} alt={advantage.title} className={classes.image} />
                            </Grid>
                        </>
                    )}
                </Grid>
            ))}
        </Box>

/*
<Box className={classes.advantagesSection}>
        {advantages.map((advantage, index) => (
            <Grid container spacing={2} className={classes.advantage} key={index}>
                {index % 2 === 0 ? (
                    <>
                        <Grid item xs={12} md={6} className={classes.imageContainer}>
                            <img src={advantage.image} alt={advantage.title} className={classes.image} />
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.textContainer}>
                            <Typography variant="h5" className={classes.title}>{advantage.title}</Typography>
                            <Typography className={classes.shortDescription}>{advantage.shortDescription}</Typography>
                            <Typography className={classes.description}>{advantage.description}</Typography>
                            <Link href={advantage.link} className={classes.link}>Читати більше →</Link>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} md={6} className={classes.textContainer}>
                            <Typography variant="h5" className={classes.title}>{advantage.title}</Typography>
                            <Typography className={classes.shortDescription}>{advantage.shortDescription}</Typography>
                            <Typography className={classes.description}>{advantage.description}</Typography>
                            <Link href={advantage.link} className={classes.link}>Читати більше →</Link>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.imageContainer}>
                            <img src={advantage.image} alt={advantage.title} className={classes.image} />
                        </Grid>
                    </>
                )}
            </Grid>
        ))}
    </Box>*/
    )
}

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        position: 'relative',
        // marginBottom: theme.spacing(4),
    },
	mainTitle: {
		color: '#19335C',
		paddingBottom: theme.spacing(2),
		position: 'relative',
		textTransform: 'uppercase',  
		fontWeight: theme.typography.fontWeightBold,  
		fontSize: theme.typography.h4.fontSize,
		zIndex: 1,
		textAlign: 'center',
		  '&::before': {
			  content: '""',
			  position: 'absolute',
			  display: 'block',
			  width: '120px',
			  height: '1px',
			  background: '#ddd',
			  bottom: '1px',
			  left: 'calc(50% - 60px)',
		  },
		  '&::after': {
			  content: '""',
			  position: 'absolute',
			  display: 'block',
			  width: '40px',
			  height: '3px',
			  background: '#47b2e4',
			  bottom: '0',
			  left: 'calc(50% - 20px)',
		  },
	},
	titleBackground: {
		  position: 'absolute',
		  top: '35%',
		  left: '50%',
		  transform: 'translate(-50%, -50%)',
		  color: '#000',
		  opacity: .05,
		  zIndex: 0,
		  letterSpacing: 6,
		  whiteSpace: 'nowrap',
		  fontWeight: theme.typography.fontWeightBold, 
		  fontSize: theme.typography.h2.fontSize
	},
}));

export default withStyles(advantagesSectionStyles)(AdvantagesSection)

