import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Layout from "../../components/elements/Layout";

import HomeScreen from "../HomeScreen/HomeScreen";
import ServiceScreen from '../ServiceScreen/ServiceScreen';
import AboutUsScreen from '../AboutUsScreen/AboutUsScreen';
import AdvantagesScreen from '../AdvantagesScreen/AdvantagesScreen';
import ContactUsScreen from '../ContactUsScreen/ContactUsScreen';
import PriceScreen from '../PriceScreen/PriceScreen';
import DirectionsScreen from '../DirectionsScreen/DirectionsScreen';
import FaqScreen from '../FaqScreen/FaqScreen';

import ServiceDetailedScreen from '../ServiceDetailedScreen/ServiceDetailedScreen';
import TruckingDetailedScreen from '../ServiceDetailedScreen/TruckingDetailedScreen';
import StorageDetailedScreen from '../ServiceDetailedScreen/StorageDetailedScreen';
import LogisticsDetailedScreen from '../ServiceDetailedScreen/LogisticsDetailedScreen';
import PackagingDetailedScreen from '../ServiceDetailedScreen/PackagingDetailedScreen';
import WarehousingDetailedScreen from '../ServiceDetailedScreen/WarehousingDetailedScreen';
import InsuranceDetailedScreen from '../ServiceDetailedScreen/InsuranceDetailedScreen';

import { services } from '../../config/myConstants';

// import LoginScreen from "../InitScreen/LoginScreen";
// import UsersScreen from '../UsersScreen/UsersScreen';
// import EventsScreen from '../EventsScreen/EventsScreen';
// import CommercialScreen from '../CommercialScreen/CommercialScreen';
// import ArchiveScreen from '../ArchiveScreen/ArchiveScreen';

import 'typeface-roboto';
import firebaseService from '../../services/Firebase/firebase';

import CustomLoader from '../../components/elements/CustomLoader';

function App() {

  const [firebaseInitialized, setFirebaseInitialized] = useState(false)
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
		firebaseService.isInitialized().then(val => {
			setFirebaseInitialized(val)
		})
	}, [])

  // useEffect(() => {
  //   setLoading(true);
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 500); 

  //   return () => clearTimeout(timer); 
  // }, [location]);


  const styles = {
		fullscreen: {
			minHeight: '100vh', 
			display: 'flex',
			// background: `url(${FonImg1}) center center no-repeat`,
			// backgroundSize: 'cover',
			justifyContent: 'center',
      alignItems: 'center',
      // border: '2px solid blue',
			// paddingTop: '100px'
		}
	}

  return (
    // <>
    //   {
    //     loading ? (
    //       <CustomLoader />
    //     ) : (
          <Routes>

            <Route 
                path="/"
                element={
                  <div style={styles.fullscreen}>
                    <HomeScreen />
                  </div>
                }
            />

            <Route index element={
                  <div style={styles.fullscreen}>
                    <HomeScreen />
                  </div>
                } 
            />

            <Route
              path="/directions"
              element={
                <div style={styles.fullscreen}>
                  <DirectionsScreen />
                </div>
              }
            />

            <Route
              path="/service"
              element={
                <div style={styles.fullscreen}>
                  <ServiceScreen />
                </div>
              }
            />

            <Route
              path="/aboutus"
              element={
                <div style={styles.fullscreen}>
                  <AboutUsScreen />
                </div>
              }
            />

            <Route
              path="/advantages"
              element={
                <div style={styles.fullscreen}>
                  <AdvantagesScreen />
                </div>
              }
            />

            <Route
              path="/contactus"
              element={
                <div style={styles.fullscreen}>
                  <ContactUsScreen />
                </div>
              }
            />

            <Route
              path="/price"
              element={
                <div style={styles.fullscreen}>
                  <PriceScreen />
                </div>
              }
            />

            <Route
              path="/faq"
              element={
                <div style={styles.fullscreen}>
                  <FaqScreen />
                </div>
              }
            />

            <Route
              path="/service-details"
              element={
                <div style={styles.fullscreen}>
                  <ServiceDetailedScreen activeCategory={services[0].title} />
                </div>
              }
            />

          <Route
            path="/services/trucking-details"
            element={
              <div style={styles.fullscreen}>
                <ServiceDetailedScreen activeCategory={services[0].title} />
                {/* <TruckingDetailedScreen activeCategory={services[0].title} /> */}
              </div>
            }
          />

          <Route
            path="/services/storage-details"
            element={
              <div style={styles.fullscreen}>
                <ServiceDetailedScreen activeCategory={services[1].title} />
                {/* <StorageDetailedScreen /> */}
              </div>
            }
          />

          <Route
            path="/services/logistics-details"
            element={
              <div style={styles.fullscreen}>
                <ServiceDetailedScreen activeCategory={services[2].title} />
                {/* <LogisticsDetailedScreen /> */}
              </div>
            }
          />

          <Route
            path="/services/packaging-details"
            element={
              <div style={styles.fullscreen}>
                <ServiceDetailedScreen activeCategory={services[3].title} />
                {/* <PackagingDetailedScreen /> */}
              </div>
            }
          />

          <Route
            path="/services/warehousing-details"
            element={
              <div style={styles.fullscreen}>
                <ServiceDetailedScreen activeCategory={services[4].title} />
                {/* <WarehousingDetailedScreen /> */}
              </div>
            }
          />

          <Route
            path="/services/insurance-details"
            element={
              <div style={styles.fullscreen}>
                <ServiceDetailedScreen activeCategory={services[5].title} />
                {/* <InsuranceDetailedScreen /> */}
              </div>
            }
          />

          <Route path="*" element={<Navigate to="/" replace />} />

        </Routes>
    //   )}
    // </>

  )
}

export default App;
