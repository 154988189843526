import React, { useEffect, useState, useRef } from 'react';

import {
    useTheme,
    Fab
} from '@material-ui/core';

import { SvgIcon } from '@material-ui/core'

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { viberFabStyles } from '../../themes/theme-white';

import { ReactComponent as ViberSvg } from '../../assets/social/viber-icon.svg';

import config from '../../config/config';

function ViberFab({classes}) {

  const theme = useTheme();

  return (
    <Fab
      className={classes.viberFab}
      href="viber://chat?number=%2B123456789" 
      target="_blank"
      aria-label="Viber"
      >
        <SvgIcon className={classes.viberIcon} component={ViberSvg}  />
    </Fab>
  )
}

export default withStyles(viberFabStyles)(ViberFab)