import React, { useEffect, useState, useRef } from 'react';

import {
    AppBar,
    Toolbar,
    Typography,
    Tabs,
    Tab,
    Box,
    makeStyles,
    useTheme,
    useMediaQuery,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Divider,
    Button,
    ListItemIcon
} from '@material-ui/core';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { navPanelstyles } from '../../themes/theme-white';

import config from '../../config/config';
const appName = config.appName

function NavPanel({classes, navRoute}) {

  const theme = useTheme();

  return (
    <Box className={classes.navigation}>
      <Typography variant="body1" style={{fontWeight: 'bold', color: '#3F51B5'}}>
        <Link to="/" className={classes.link}>
          Головна
        </Link>
          <span style={{padding: '0 15px'}}>/</span>
          {/* <span>
            Напрямки
          </span>
          <span style={{padding: '0 15px'}}>/</span>
          <span style={{color: '#0E1D4E'}}>
            Пакування
          </span> */}
          {navRoute.map((route, index) => (
            <React.Fragment key={route}>
              <span style={{ color: index === navRoute.length - 1 ? '#0E1D4E' : '#3F51B5' }}>
                {route}
              </span>
              {index < navRoute.length - 1 && <span style={{ padding: '0 15px' }}>/</span>}
            </React.Fragment>
          ))}
      </Typography>
    </Box>
  )
}

export default withStyles(navPanelstyles)(NavPanel)